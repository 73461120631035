<template>
  <div class="columns is-multiline is-vcentered">
    <div class="column is-full">
      <slot />
    </div>

    <div class="column is-full">
      <a
        href="https://chat.whatsapp.com/JwAYeF8F7QX0L86mtsL2Rp"
        target="_blank"
        rel="noopener noreferrer"
        class="button is-primary has-text-white"
      >
        <font-awesome-icon
          :icon="['fab', 'whatsapp']"
          class="mr-2"
        />
        Acessar comunidade
      </a>
    </div>
  </div>
</template>
