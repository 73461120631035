import Link from '@/models/user/bio/link';
import Style from '@/models/user/bio/style';
import Profile from '@/models/user/bio/profile';

class UserBio {
  id: number;

  username: string;

  style: Style;

  live: boolean;

  profile: Profile;

  links: Link[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    // eslint-disable-next-line no-underscore-dangle
    this.id = data.id || data._id || data.Id;
    this.username = data.username;
    this.style = new Style(data.style);
    this.live = data.live;
    this.profile = new Profile(data.profile);
    this.links = data.links
      ? data.links.map((link: Link) => new Link(link))
      : [];
  }

  getLinks(): Link[] {
    return this.links.map((link) => new Link(link));
  }

  sortLinks(): void {
    this.links.sort((a, b) => a.order - b.order);
  }

  resetOrder(): void {
    this.links.map((link, i) => {
      // eslint-disable-next-line no-param-reassign
      link.order = i;
      return link;
    });
  }

  deleteLink(link: Link): void {
    const index = this.links.findIndex((l) => l.id === link.id);
    if (index < 0) return;
    this.links.splice(index, 1);
    this.resetOrder();
  }

  toggleLinkStatus(link: Link): void {
    const index = this.links.findIndex((l) => l.id === link.id);
    if (index < 0) return;
    this.links[index].active = !this.links[index].active;
    this.sortLinks();
  }

  moveLinkUp(link: Link): void {
    const index = this.links.findIndex((l) => l.id === link.id);
    if (index <= 0) return;
    this.links.splice(index, 1);
    this.links.splice(index - 1, 0, link);
    this.links.map((l, i) => {
      // eslint-disable-next-line no-param-reassign
      l.order = i;
      return l;
    });
    this.sortLinks();
  }

  moveLinkDown(link: Link): void {
    const index = this.links.findIndex((l) => l.id === link.id);
    if (index < 0 || index >= this.links.length - 1) return;
    this.links.splice(index, 1);
    this.links.splice(index + 1, 0, link);
    this.links.map((l, i) => {
      // eslint-disable-next-line no-param-reassign
      l.order = i;
      return l;
    });
    this.sortLinks();
  }

  getLinkStore(): Link | undefined {
    if (!this.links || this.links.length === 0) return undefined;
    return this.links.find((link) => link.isStore());
  }

  static newUserBio(name: string, username: string): UserBio {
    return new UserBio({
      username,
      style: Style.newStyle(),
      live: false,
      profile: Profile.newProfile(name),
      links: [],
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): UserBio[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        v[camelCaseKey] = row[index];
      });
      return new UserBio(v);
    });
  }
}

export default UserBio;
