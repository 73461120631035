import Image from '@/models/image';
import Social from '@/models/user/bio/social';

class Profile {
  img: Image | null;

  name: string;

  description: string;

  category: string;

  social: Social;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.img = data.img ? new Image(data.img) : null;
    this.name = data.name;
    this.description = data.description;
    this.category = data.category;
    this.social = new Social(data.social);
  }

  static newProfile(name: string): Profile {
    return new Profile({
      img: null,
      name,
      description: '',
      category: '',
      social: Social.newSocial(),
    });
  }
}

export default Profile;
