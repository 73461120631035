import Image from '@/models/image';

class Merchant {
  uuid: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  createdByUuid: string;

  status: string;

  name: string;

  adminsFull: Array<string>;

  adminsRead: Array<string>;

  adminsInvited: Array<string>;

  website: string;

  facebook: string;

  linkedin: string;

  instagram: string;

  tiktok: string;

  youtube: string;

  coverImages: Array<string>;

  logoImage: Image;

  shortDescription: string;

  description: string;

  yampiBrandId: string;

  productGateway: string;

  constructor({
    uuid,
    createdAt,
    updatedAt,
    deletedAt,
    status,
    createdByUuid,
    name,
    adminsFull,
    adminsRead,
    adminsInvited,
    website,
    facebook,
    linkedin,
    instagram,
    tiktok,
    youtube,
    coverImages,
    logoImage,
    shortDescription,
    description,
    yampiBrandId,
    productGateway,
  }: {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    status: string;
    createdByUuid: string;
    name: string;
    adminsFull: Array<string>;
    adminsRead: Array<string>;
    adminsInvited: Array<string>;
    website: string;
    facebook: string;
    linkedin: string;
    instagram: string;
    tiktok: string;
    youtube: string;
    coverImages: Array<string>;
    logoImage: Image;
    shortDescription: string;
    description: string;
    yampiBrandId: string;
    productGateway: string;
  }) {
    this.uuid = uuid;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.status = status;
    this.createdByUuid = createdByUuid;
    this.name = name;
    this.adminsFull = adminsFull;
    this.adminsRead = adminsRead;
    this.adminsInvited = adminsInvited;
    this.facebook = facebook;
    this.website = website;
    this.facebook = facebook;
    this.linkedin = linkedin;
    this.instagram = instagram;
    this.tiktok = tiktok;
    this.youtube = youtube;
    this.coverImages = coverImages;
    this.logoImage = logoImage;
    this.coverImages = coverImages;
    this.shortDescription = shortDescription;
    this.description = description;
    this.description = description;
    this.yampiBrandId = yampiBrandId;
    this.yampiBrandId = yampiBrandId;
    this.productGateway = productGateway;
  }

  formattedProductGateway(): string {
    if (this.productGateway === 'livelink-yampi') {
      return 'Checkout completo';
    }
    if (this.productGateway === 'external-link') {
      return 'Links externos';
    }
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): Merchant[] {
    if (rows.length === 0) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (camelCaseKey === 'logoImage') {
          if (row[index] === null || row[index].uuid === '00000000-0000-0000-0000-000000000000') {
            v[camelCaseKey] = null;
          } else {
            v[camelCaseKey] = new Image({
              uuid: row[index].uuid,
              cdnUrl: row[index].cdn_url,
              cdnUrlModifiers: row[index].cdn_url_modifiers,
              mimeType: row[index].mime_type,
            });
          }
        } else if (
          row[index]
          && (
            camelCaseKey === 'updatedAt'
            || camelCaseKey === 'createdAt'
            || camelCaseKey === 'deletedAt'
          )
        ) {
          v[camelCaseKey] = new Date(row[index]);
        } else {
          v[camelCaseKey] = row[index];
        }
        // v[camelCaseKey] = row[index];
      });
      return new Merchant(v);
    });
  }
}

export default Merchant;
