import Image from '@/models/image';
import Price from '@/models/user/bio/price';

class Product {
  id: string;

  merchantId: string;

  name: string;

  img: Image | null;

  url: string;

  price: Price;

  order: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.id = `${data.id}`;
    this.merchantId = data.merchantId;
    this.name = data.name;
    this.img = data.img ? new Image(data.img) : null;
    this.url = data.url;
    this.price = new Price(data.price);
    this.order = data.order;
  }

  getId() {
    return this.id;
  }

  getName(): string {
    return this.name?.replace(/ ref\. \d+ ?/g, ' ');
  }

  getImageUrl(): string {
    return this.img ? this.img.cdnUrl : '';
  }

  getFormattedPrice(): string {
    return this.price.getFormattedPrice();
  }

  formattedPrice(): string {
    if (this.price.sale <= 0.0) {
      return '<strong>Grátis</strong>';
    }

    const priceSale = this.price.sale.toLocaleString('pt-BR', { style: 'currency', currency: this.price.currency });
    const priceDiscount = this.price.discount.toLocaleString('pt-BR', { style: 'currency', currency: this.price.currency });

    if (this.price.discount <= 0.0) {
      return `${priceSale}`;
    }

    return `<span class='line-through'>${priceSale}</span> ${priceDiscount}`;
  }
}

export default Product;
