class ScheduledMetadata {
  id: string;

  account: string;

  liveDate: Date;

  platform: string;

  name: string;

  status: string;

  createdAt: Date;

  durationInMinutes: number;

  constructor({
    id,
    account,
    liveDate,
    platform,
    name,
    status,
    createdAt,
    durationInMinutes,
  }: {
    id: string
    account: string
    liveDate: Date
    platform: string
    name: string
    status: string
    createdAt: Date
    durationInMinutes: number
  }) {
    // eslint-disable-next-line no-underscore-dangle
    this.id = id;
    this.account = account;
    this.liveDate = liveDate;
    this.platform = platform;
    this.name = name;
    this.status = status;
    this.createdAt = createdAt;
    this.durationInMinutes = durationInMinutes;
  }

  formattedDurationInMinutes(): string {
    switch (this.durationInMinutes) {
      case 60:
        return `${this.durationInMinutes} minutos`;
      default:
        return `${this.durationInMinutes / 60} horas`;
    }
  }

  formattedPlatform(): string {
    switch (this.platform) {
      case 'insta':
        return 'Instagram';
      default:
        return '-';
    }
  }

  formattedStatus(): string {
    switch (this.status) {
      case 'scheduled':
        return 'Agendado';
      default:
        return '-';
    }
  }

  formattedDatetime(): string {
    return this.liveDate.toLocaleString();
  }

  formattedCreatedAt(): string {
    return this.createdAt.toLocaleString();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): ScheduledMetadata[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (camelCaseKey === 'Id') {
          v.id = row[index];
        } else if (camelCaseKey === 'liveDate') {
          v[camelCaseKey] = new Date(row[index]);
        } else if (camelCaseKey === 'createdAt') {
          v[camelCaseKey] = new Date(row[index]);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new ScheduledMetadata(v);
    });
  }
}

export default ScheduledMetadata;
