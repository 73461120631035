import Image from '@/models/image';

class Live {
  name: string | null;

  img: Image | null;

  description: string | null;

  date: Date | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.name = data ? data.name : null;
    this.img = data && data.img ? new Image(data.img) : null;
    this.description = data ? data.description : null;
    this.date = data && data.date ? new Date(data.date) : null;
  }

  getFormattedDate(): string | undefined {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return this.date?.toLocaleDateString('pt-BR', options);
  }
}

export default Live;
