import { RouteRecordRaw } from 'vue-router';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';
import MerchantManagerLayout from '@/components/layout/MerchantManagerLayout.vue';
import MerchantExternalLiveReportLayout from '@/components/layout/MerchantExternalLiveReportLayout.vue';
import MerchantExternalLiveReportAdminLayout from '@/components/layout/MerchantExternalLiveReportAdminLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/merchants',
    name: 'MyMerchantsPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/MyMerchantsView.vue'),
  },
  {
    path: '/merchants/new',
    name: 'MerchantCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/MerchantCreateView.vue'),
  },
  {
    path: '/merchants/:uuid/settings',
    name: 'MyMerchantManagerPage',
    meta: {
      layout: MerchantManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/manager/MerchantManagerView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/locations',
    name: 'MyMerchantManagerLocationPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/manager/LocationView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/address/new',
    name: 'MerchantAddressCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant//manager/MerchantAddressesCreateView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/admins',
    name: 'MyMerchantManagerAdminsPage',
    meta: {
      layout: MerchantManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/manager/AdminsView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/admins/add',
    name: 'MerchantCreateAdminPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/manager/MerchantCreateAdminView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/billing',
    name: 'MyMerchantManagerBankingInfoPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/manager/BankingInfoView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/description',
    name: 'MyMerchantManagerDescriptionPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/manager/EditDescriptionView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/policies',
    name: 'MyMerchantManagerPoliciesPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantManager" */ '@/views/merchant/manager/PoliciesView.vue'),
  },
  {
    path: '/merchants/lr/:date/:platform/:account/full',
    name: 'MyMerchantExternalLiveReportPage',
    meta: {
      layout: MerchantExternalLiveReportLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantExternalLiveRerport" */ '@/views/merchant/manager/reports/ExternalLiveReport.vue'),
  },
  {
    path: '/merchants/lr/:date/:platform/:account',
    name: 'GuestExternalLiveReportPage',
    meta: {
      layout: MerchantExternalLiveReportLayout,
      page: 1,
      limit: 1500,
      accessWithSession: null,
    },
    component: () => import(/* webpackChunkName: "merchantExternalLiveRerport" */ '@/views/merchant/manager/reports/ExternalLiveReport.vue'),
  },
  {
    path: '/merchants/lr/admin',
    name: 'MyMerchantExternalLiveReportAdminPage',
    meta: {
      layout: MerchantExternalLiveReportAdminLayout,
      accessWithSession: null,
    },
    component: () => import(/* webpackChunkName: "merchantExternalLiveRerport" */ '@/views/merchant/manager/reports/ExternalLiveReportAdmin.vue'),
  },
];

export default routes;
