import { ref, watch, computed } from 'vue';
import { defineStore } from 'pinia';
// import useAxios from '@/libs/axios';
import eventBus from '@/eventBus/eventBus';
import Product from '@/models/yampi/product/product';
import Sku from '@/models/yampi/product/sku';
import ShoppingCart from '@/models/yampi/shoppingCart/shoppingCart';
import ProductQuantity from '@/models/yampi/shoppingCart/productQuantity';

export default defineStore('shoppingCartStore', () => {
  // const axios = useAxios();

  /*
    State
  */
  const shoppingCartStore = ref({
    shoppingCart: {} as ShoppingCart,
  });

  /*
    Computed
  */
  const getShoppingCart = computed(() => (
    streamUuid: string,
  ): ShoppingCart | undefined => {
    if (shoppingCartStore.value.shoppingCart.streamUuid === streamUuid) {
      return shoppingCartStore.value.shoppingCart;
    }
    return undefined;
  });

  const isProductSkuInCart = computed(
    () => (streamUuid: string, productSku: string): boolean => {
      if (
        streamUuid === undefined || streamUuid.trim() === ''
        || productSku === undefined || productSku.trim() === ''
      ) {
        return false;
      }
      if (shoppingCartStore.value.shoppingCart.streamUuid === streamUuid) {
        const r = shoppingCartStore.value.shoppingCart.productQuantity.filter(
          (pq: ProductQuantity) => pq.productSku?.sku === productSku,
        );
        return r.length > 0;
      }
      return false;
    },
  );

  const getShoppingCartTotal = computed(() => (streamUuid: string): number => {
    if (streamUuid === undefined) {
      alert('Live não encontrada (scErr)');
    }
    if (shoppingCartStore.value.shoppingCart.streamUuid === streamUuid) {
      return shoppingCartStore.value.shoppingCart.productQuantity.length;
    }
    return 0;
  });

  const formatPrice = computed(() => (price: number): string => {
    const minOptions = {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    const min = new Intl.NumberFormat('pt-BR', minOptions).format(price);
    return min;
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('shoppingCartStore');
    const v = JSON.parse(localStorage.getItem('shoppingCartStore') || '{}');
    shoppingCartStore.value.shoppingCart = new ShoppingCart(v.shoppingCart);
  };

  const shoppingCartsDoAdd = async (
    streamUuid: string,
    product: Product,
    productSku: Sku,
    quantity: number,
  ) => {
    // create if not exists
    if (
      shoppingCartStore.value.shoppingCart.streamUuid === undefined
      || shoppingCartStore.value.shoppingCart.streamUuid !== streamUuid
    ) {
      shoppingCartStore.value.shoppingCart = new ShoppingCart({
        streamUuid,
        productQuantity: [],
        total: 0,
      });
    }

    shoppingCartStore.value.shoppingCart.updatedAt = new Date();

    let alreadyInCart = false;
    shoppingCartStore.value.shoppingCart.productQuantity.forEach((pq) => {
      if (pq.productSku?.token === productSku.token) {
        alreadyInCart = true;
      }
    });
    if (alreadyInCart) {
      return false;
    }
    shoppingCartStore.value.shoppingCart.addToCart(product, productSku, quantity);
    return shoppingCartStore.value.shoppingCart;
  };

  /*
    Hooks
  */

  /*
    Config
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('shoppingCartStore')) {
    const v = JSON.parse(localStorage.getItem('shoppingCartStore') || '{}');
    v.shoppingCart.productQuantity = v.shoppingCart.productQuantity.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (pq: any) => new ProductQuantity(pq),
    );
    shoppingCartStore.value.shoppingCart = new ShoppingCart(v.shoppingCart);
  }

  watch(shoppingCartStore, (shoppingCartsVal) => {
    localStorage.setItem('shoppingCartStore', JSON.stringify(shoppingCartsVal));
  }, {
    deep: true,
  });

  return {
    shoppingCartStore,
    shoppingCartModel: ShoppingCart,
    resetStore,
    formatPrice,
    // increaseQuantity,
    isProductSkuInCart,
    getShoppingCart,
    getShoppingCartTotal,
    shoppingCartsDoAdd,
  };
});
