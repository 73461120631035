import { ref, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import Stream from '@/models/stream';
import RealTimeTokens from '@/models/realTimeTokens';
import Pagination from '@/models/pagination';
import eventBus from '@/eventBus/eventBus';

export default defineStore('streamStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const streamStore = ref({
    myStream: {} as Stream,
    realtimeTokens: {} as RealTimeTokens,
    streams: {
      data: [] as Stream[],
      pagination: {} as Pagination,
    },
  });

  /*
    Computed
  */
  const getMyStream = (() => {
    const { myStream } = streamStore.value;
    return myStream;
  });
  const getStreamByUUID = computed(() => {
    const { streams } = streamStore.value;
    return (uuid: string) => streams.data.find((stream) => stream.uuid === uuid);
  });
  const getStreamByUri = computed(() => {
    const { streams } = streamStore.value;
    return (uri: string) => streams.data.find((stream) => stream.uri === uri);
  });
  const getStreamHour = computed(() => {
    const { myStream } = streamStore.value;
    if (!myStream.scheduledDate) {
      return '';
    }
    const date = new Date(myStream.scheduledDate);
    const hour = date.getHours();
    const minutes = date.getMinutes();
    return `${hour}:${minutes}`;
  });
  const getStreamDate = computed(() => {
    const { myStream } = streamStore.value;
    if (!myStream.scheduledDate) {
      return '';
    }
    const date = new Date(myStream.scheduledDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  });

  /*
    Methods
  */
  const formattedStreamStatus = computed(() => (status: string) => {
    switch (status) {
      case 'active':
        return 'Ativo';
      case 'inactive':
        return 'Inativo';
      case 'pending':
        return 'Cadastro Incompleto';
      case 'deleted':
        return 'Deletado';
      default:
        return 'Desconhecido';
    }
  });
  const formattedLandingPageStatus = computed(() => (active: boolean) => {
    switch (active) {
      case true:
        return 'Landing Page';
      case false:
        return 'Landing Page';
      default:
        return 'Desconhecido';
    }
  });

  const formattedStreamStatusDescription = computed(() => (status: string) => {
    switch (status) {
      case 'pending':
        return '** Preencha as informações necessárias para ativar sua live e poder iniciar a transmissão';
      default:
        return undefined;
    }
  });
  const formatStreamDate = ((
    stream: Stream,
    minutesToAdd: 0,
    locale: 'en-US',
    isoFormat: false,
  ): string | null => {
    const date = new Date(stream.scheduledDate);

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    // Create the date object directly with the string representation
    if (Number.isNaN(date.getTime())) {
      return null;
    }

    if (minutesToAdd !== 0 && !Number.isNaN(minutesToAdd)) {
      date.setMinutes(date.getMinutes() + minutesToAdd);
    }
    if (isoFormat) {
      return date.toISOString();
    }
    return date.toLocaleDateString(locale, options);
  });

  const updateStoreStreams = (streams: Stream[]) => {
    const existingStreamIndex = streamStore.value.streams.data.findIndex(
      (stream) => stream.uuid === streams[0].uuid,
    );
    if (existingStreamIndex !== -1) { // Stream exists in the list, update its properties
      Object.assign(
        streamStore.value.streams.data[existingStreamIndex],
        streams[0],
      );
    } else { // The stream doesn't exist in the list, add it to the list
      streamStore.value.streams.data.push(streams[0]);
    }
    if (streams.length === 1) {
      [streamStore.value.myStream] = streams;
    }
  };

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('streamStore');
    // streamStore.value = JSON.parse(localStorage.getItem('streamStore') || '{}');
  };

  const streamDoCreate = async (
    name: string,
    merchantUuid: string,
    scheduledDate: Date,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      name,
      merchant_uuid: merchantUuid,
      scheduled_date: scheduledDate,
    };

    return axios.post('/streams/add', payload, config)
      .then((response) => response)
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  // Get all streams logged in user has access to
  const streamsDoGetMy = async (params: {
    uuid: string,
    merchantUuid: string,
  }) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    // If uuid is passed, get only the stream with that uuid
    const queryParam = [] as string[];
    if (params && params.uuid) {
      queryParam.push(`uuid=${params.uuid}`);
    }
    if (params && params.merchantUuid) {
      queryParam.push(`m=${params.merchantUuid}`);
    }
    if (queryParam.length > 0) {
      queryParam.join('&');
    }

    return axios.get(`/streams/my?${queryParam}`, config)
      .then(async (response) => {
        if (response.status === 204) { // No content
          streamStore.value.streams.data = [] as Stream[];
          streamStore.value.streams.pagination = new Pagination({
            total: 0,
            perPage: 0,
            currentPage: 0,
            lastPage: 0,
          });
          return streamStore.value.streams;
        }

        const { columns, rows, pagination } = response.data;
        const streams = Stream.fromApiResponse(columns, rows);

        // if (streams.length === 0) {
        //   streamStore.value.streams.pagination = new Pagination();
        //   return streamStore.value.streams;
        // }

        // If a uuid was passed, update only the stream with that uuid
        if (params && params.uuid !== undefined) {
          await updateStoreStreams(streams);
        } else { // If no uuid was passed, update the whole list
          streamStore.value.streams.data = streams;
          streamStore.value.streams.pagination = pagination;
        }
        return streamStore.value.streams;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  // Get a single stream by its uri
  // if not found or error, return undefined
  const streamDoGet = async (uri?: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };
    return axios.get(`/streams/${uri}`, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          // streamStore.value.streams.data = streams;
          // streamStore.value.streams.pagination = pagination;
          return undefined;
        }

        const { Stream: singleStream } = response.data;
        const streams = Stream.fromApiResponse(singleStream.columns, [singleStream.row]);

        if (streams.length === 0) {
          return undefined;
        }

        const existingStreamIndex = streamStore.value.streams.data.findIndex(
          (stream) => stream.uuid === streams[0].uuid,
        );
        if (existingStreamIndex !== -1) { // Stream exists in the list, update its properties
          Object.assign(
            streamStore.value.streams.data[existingStreamIndex],
            streams[0],
          );
        } else { // The stream doesn't exist in the list, add it to the list
          streamStore.value.streams.data.push(streams[0]);
        }
        return streams[0];
      })
      .catch(() => undefined);
  };

  const streamsDoGetRealtimeTokens = async (uuid: string, role: number, chatUserId: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      stream_uuid: uuid,
      role,
      chat_user_id: chatUserId,
    };

    let url = '/streams/realtime/watch';
    if (role === 1) {
      url = '/streams/realtime/broadcast';
    }

    return axios.post(url, payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          // streamStore.value.streams.data = streams;
          // streamStore.value.streams.pagination = pagination;
          return undefined;
        }

        const { columns, row } = response.data;
        const realTimeTokens = RealTimeTokens.fromApiResponse(columns, [row]);

        if (realTimeTokens.length === 0) {
          return undefined;
        }

        [streamStore.value.realtimeTokens] = realTimeTokens;
        return streamStore.value.realtimeTokens;
      })
      .catch(() => undefined);
  };

  const streamsDoUpdateMyStream = async (
    params: Stream,
    updateImageLogo: boolean,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    let payloadWithImage = {};

    const payload = {
      uuid: params.uuid,
      status: params.status,
      name: params.name,
      description: params.description,
      uri: params.uri,
      instagram: params.instagram,
      tiktok: params.tiktok,
      youtube: params.youtube,
      facebook: params.facebook,
      linkedin: params.linkedin,
      admins_invited: params.adminsInvited,
      scheduled_date: params.scheduledDate,
      landing_page_active: params.landingPageActive,
      products_uuid: params.productsUuid,
      active_products_id: params.activeProductsId,
      show_products_on_landing: params.showProductsOnLanding,
      recording_status: params.recordingStatus,
      rtmp_url: params.rtmpUrl,
      bg_image_url: params.bgImageUrl,
      stream_mode: params.streamMode,
    };

    if (updateImageLogo) {
      const coverImage = {
        uuid: params.coverImage.uuid,
        cdn_url: params.coverImage.cdnUrl,
        cdn_url_modifiers: params.coverImage.cdnUrlModifiers,
        mime_type: params.coverImage.mimeType,
      };

      payloadWithImage = {
        ...payload,
        cover_image: coverImage,
      };
    }

    return axios.post('/streams/my/update', updateImageLogo ? payloadWithImage : payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }
        const { message, columns, row } = response.data;
        const streams = Stream.fromApiResponse(columns, [row]);

        updateStoreStreams(streams);
        return {
          message,
          myStream: streamStore.value.myStream,
        };
      })
      .catch((error) => {
        throw error;
      });
  };

  const streamsDoRequestStreamDownload = async (
    uuid: string,
    m3u8URL: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      m3u8_url: m3u8URL,
    };

    return axios.post(`/streams/${uuid}/recordings/download`, payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const streamsDoGetRecordings = async (
    uuid: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    return axios.get(`/streams/${uuid}/recordings`, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const streamsDoDelete = async (
    uuid: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      uuid,
      delete: true,
    };

    return axios.post('/streams/my/update', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }

        streamStore.value.streams.data = streamStore.value.streams.data.filter(
          (stream) => stream.uuid !== uuid,
        );

        return true;
      })
      .catch((error) => {
        throw error;
      });
  };

  /*
    Hooks
  */

  /*
    Config
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('streamStore')) {
    streamStore.value = JSON.parse(localStorage.getItem('streamStore') || '{}');
  }

  watch(streamStore, (streamsVal) => {
    localStorage.setItem('streamStore', JSON.stringify(streamsVal));
  }, {
    deep: true,
  });

  return {
    streamModel: Stream,
    formatStreamDate,
    resetStore,
    streamStore,
    getMyStream,
    getStreamHour,
    getStreamDate,
    getStreamByUUID,
    getStreamByUri,
    formattedStreamStatus,
    formattedLandingPageStatus,
    formattedStreamStatusDescription,
    streamsDoGetMy,
    streamDoGet,
    streamDoCreate,
    streamsDoDelete,
    streamsDoGetRecordings,
    streamsDoUpdateMyStream,
    streamsDoGetRealtimeTokens,
    streamsDoRequestStreamDownload,
  };
});
