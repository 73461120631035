class UtmParams {
  utmSource: string | null;

  utmMedium: string | null;

  utmCampaign: string | null;

  utmTerm: string | null;

  utmContent: string | null;

  expiresAt: Date;

  constructor({
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
  }: {
    utmSource: string | null;
    utmMedium: string | null;
    utmCampaign: string | null;
    utmTerm: string | null;
    utmContent: string | null;
  }) {
    this.utmSource = utmSource;
    this.utmMedium = utmMedium;
    this.utmCampaign = utmCampaign;
    this.utmTerm = utmTerm;
    this.utmContent = utmContent;
    this.expiresAt = this.calculateExpireTime();
  }

  // eslint-disable-next-line class-methods-use-this
  calculateExpireTime(hours = 5): Date {
    const now = new Date();
    now.setHours(now.getHours() + hours);
    return now;
  }
}

export default UtmParams;
