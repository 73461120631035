export default class Pagination {
  total = null as number | null;

  count = null as number | null;

  perPage = null as number | null;

  currentPage = null as number | null;

  totalPages = null as number | null;

  links = [] as string[] | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    Object.assign(this, data);

    if (this.links) {
      this.links = this.links.map((link) => link.replace(/&amp;/g, '&'));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(data: any): Pagination {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const v: any = {};
    Object.keys(data).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const camelCaseKey = key.replace(/_([a-z])/g, (_: any, letter: any) => letter.toUpperCase());
      v[camelCaseKey] = data[key];
    });
    v.totalPages = v.lastPage || v.last_page;
    return new Pagination(v);
  }
}
