/* eslint-disable @typescript-eslint/no-explicit-any */
export default class Variation {
  name = null as string | null;

  id = null as number | null;

  value = null as string | null;

  valueId = null as number | null;

  constructor(data: Variation) {
    Object.assign(this, data);

    // If you want to parse the nested customizations property as well, you can do so here.
    // this.customizations.data = this.customizations.data.map((customizationData: any) => {

    //   // Parse customization data if needed
    //   return customizationData;
    // });
  }

  static fromApiResponse(columns: string[], rows: any[][]): Variation[] {
    if (rows.length === 0) {
      return [];
    }

    return rows.map((row: any[]) => {
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        v[camelCaseKey] = row[index];
      });
      return new Variation(v);
    });
  }

  static fromModelApiResponse(data: any[]) {
    if (data.length === 0) {
      return [];
    }
    return data.map((row: any) => {
      const v: any = {};
      Object.keys(row).forEach((key: string) => {
        const camelCaseKey = key.replace(/_([a-z])/g, (_: any, letter: any) => letter.toUpperCase());
        v[camelCaseKey] = row[key];
      });
      return new Variation(v);
    });
  }
}
