import Buttons from '@/models/user/bio/buttons';
import Bio from '@/models/user/bio/bio';

class Style {
  buttons: Buttons;

  bio: Bio;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.buttons = new Buttons(data.buttons);
    this.bio = new Bio(data.bio);
  }

  static newStyle(): Style {
    return new Style({
      buttons: Buttons.newButtons(),
      bio: Bio.newBio(),
    });
  }
}

export default Style;
