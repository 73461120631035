import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import Product from '@/models/yampi/product/product';
import SKU from '@/models/yampi/product/sku';
import Pagination from '@/models/yampi/product/pagination';
import eventBus from '@/eventBus/eventBus';

export default defineStore('liveLinkProductStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const liveLinkProductStore = ref({
    selectedProduct: {} as Product,
    products: {
      data: [] as Product[],
      pagination: {} as Pagination,
    },
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('liveLinkProductStore');
    liveLinkProductStore.value.products = {
      data: [] as Product[],
      pagination: {} as Pagination,
    };
  };

  const productsDoSearch = async (params: {
    merchantUuid: string,
    streamUuid: string,
    page: number | 1,
    limit: number | 10,
    id?: number | undefined,
  }, skipCache = false) => {
    // Search products from API
    // Search only merchant products if merchantUuid is provided
    // Search only stream products if streamUuid is provided
    // Both are provided, it search only merchant products
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      merchant_uuid: params.merchantUuid,
      stream_uuid: params.streamUuid,
      product_gateway: 'livelink-yampi',
      skip_cache: skipCache,
      limit: params.limit,
      page: params.page,
      id: params.id ? `${params.id}` : '',
    };

    return axios.post('/products/search', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }
        if (response.status === 204) {
          return undefined;
        }

        const { columns, rows, pagination } = response.data;
        const products = Product.fromApiResponse(columns, rows);
        liveLinkProductStore.value.products.data = products;
        liveLinkProductStore.value.products.pagination = Pagination.fromApiResponse(pagination);

        return liveLinkProductStore.value.products;
      }).catch((error) => {
        throw new Error(error.message);
      });
  };

  const productsDoCreate = async (merchantUuid: string, params: {
    simple: boolean,
    brandId: number,
    active: boolean,
    isDigital: boolean,
    name: string,
    variationsIds: number[],
  }, skus: SKU[]) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      merchant_uuid: merchantUuid,
      simple: params.simple,
      brand_id: params.brandId,
      active: params.active,
      is_digital: params.isDigital,
      name: params.name,
      variations_ids: params.variationsIds,
      skus: skus?.map((sku) => ({
        sku: sku.sku,
        price_cost: sku.priceCost,
        price_sale: sku.priceSale,
        price_discount: sku.priceDiscount,

        weight: sku.weight,
        width: sku.width,
        height: sku.height,
        length: sku.length,
        quantity_managed: false,
        availability: 1,
        availability_soldout: -1,
        blocked_sale: sku.blockedSale,
        variations_values_ids: sku.variationsValuesIds,
        images: [],
      })),
    };

    return axios.post('/products/create', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) {
          return undefined;
        }

        const { columns, row } = response.data;
        const products = Product.fromApiResponse(columns, [row]);

        if (products.length === 0) {
          return undefined;
        }

        return products[0];
      });
  };

  const productsDoUpdate = async (id: number, params: {
    merchantUuid: string,
    simple: boolean,
    brandId: number,
    active: boolean,
    isDigital: boolean,
    name: string,
    description: string,
    specifications: string,
    measures: string, // USE TO STORE IMAGES URL ONLY
    warranty: number,
    hasVariations: boolean,
    variationsIds: number[],
    images: [],
  }, skus: SKU[]) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      merchant_uuid: params.merchantUuid,
      simple: params.simple,
      brand_id: params.brandId,
      active: params.active,
      is_digital: params.isDigital,
      name: params.name,
      description: params.description,
      specifications: params.specifications,
      measures: params.measures,
      warranty: params.warranty,
      hasVariations: params.hasVariations,
      variations_ids: params.variationsIds,

      product_id: id,
      skus: skus.map((sku, index) => ({
        id: sku.id,
        sku: sku.token,
        price_cost: sku.priceCost,
        price_sale: sku.priceSale,
        price_discount: sku.priceDiscount,
        weight: sku.weight > 0 ? sku.weight : 1,
        width: sku.width > 0 ? sku.width : 1,
        height: sku.height > 0 ? sku.height : 1,
        length: sku.length > 0 ? sku.length : 1,
        quantity_managed: sku.quantityManaged,
        availability: 1,
        availability_soldout: -1,
        blocked_sale: sku.blockedSale,

        variations_values_ids: sku.variations.map((variation) => variation.valueId),
        images: index === 0 ? params.images?.map((image: string) => ({ url: image })) : {},
      })),
    };

    return axios.post('/products/update', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          throw new Error(response.statusText);
        }

        if (response.status === 204) {
          return undefined;
        }
        return true;
      }).catch((error) => {
        console.log(error);
        throw new Error(error.message);
      });
  };

  const productsDoUpdateGroup = async (id: number, productsIds: number[]) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
        'User-Token': process.env.VUE_APP_YAMPI_USER_TOKEN,
        'User-Secret-Key': process.env.VUE_APP_YAMPI_USER_SECRET_KEY,
      },
    };

    const payload = {
      products_ids: productsIds,
    };

    return axios.put(`https://api.dooki.com.br/v2/conhecendo-yampi/catalog/groups/${id}/products`, payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) {
          return undefined;
        }
        return true;
      }).catch((error) => {
        console.log(error);
        return undefined;
      });
  };

  const productsDoDelete = async (id: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
        'User-Token': process.env.VUE_APP_YAMPI_USER_TOKEN,
        'User-Secret-Key': process.env.VUE_APP_YAMPI_USER_SECRET_KEY,
      },
    };

    return axios.delete(`https://api.dooki.com.br/v2/conhecendo-yampi/catalog/products/${id}`, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        return true;
      }).catch((error) => {
        console.log(error);
        return undefined;
      });
  };

  /*
    Config
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('liveLinkProductStore')) {
    const v = JSON.parse(localStorage.getItem('liveLinkProductStore') || '{}');
    liveLinkProductStore.value.products.data = v.products.data.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (row: any): Product => new Product(row),
    );
    liveLinkProductStore.value.products.pagination = new Pagination(v.products.pagination);
  }

  watch(liveLinkProductStore, (productsVal) => {
    localStorage.setItem('liveLinkProductStore', JSON.stringify(productsVal));
  }, {
    deep: true,
  });

  return {
    productModel: Product,
    resetStore,
    // getSortedProducts,
    liveLinkProductStore,
    productsDoSearch,
    productsDoCreate,
    productsDoDelete,
    productsDoUpdate,
    productsDoUpdateGroup,
  };
});
