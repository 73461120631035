import mixpanelStore from '@/store/mixpanel/mixpanel';

const config = {
  MinMessageLength: 1,
  EventName: 'Broadcast Live',
  EventActions: {
    Click: 'Click',
    Send: 'Send',
  },
  EventActionsDetail: {
    Chat: {
      Message: 'Chat Message',
      Question: 'Chat Question',
    },
    Product: {
      Detail: 'Product Detail',
      CarouselBuyNow: 'Product Carousel Buy Now',
      DetailBuyNow: 'Product Detail Buy Now',
      CarouselCartAdd: 'Product Carousel Cart Add',
      DetailCartAdd: 'Product Detail Cart Add',
      DetailToggleStatus: 'Product Detail Toggle Status',

      ShoppingCartIncreaseQty: 'Shopping Cart Increase Qty',
      ShoppingCartReduceQty: 'Shopping Cart Reduce Qty',
      ShoppingCartCheckoutClick: 'Shopping Cart Checkout Click',
    },
  },
  EventKeys: {
    IsAdmin: 'Is Admin',
    Chat: {
      MessageText: 'Text',
      UserName: 'UserName',
    },
    Product: {
      Name: 'Product Name',
      Names: 'Products Name',
      TotalPrice: 'Total Price',
      Price: 'Product Price',
      Image: 'Product Image',
      Gateway: 'Product Gateway',
    },
  },
};

class BroadcastLive {
  eventName: string;

  constructor() {
    this.eventName = config.EventName;
  }

  track(
    eventAction: string,
    actionDetail: string,
    merchantUuid: string,
    streamUuid: string,

    props: Record<string, string | boolean | null>,
  ) {
    mixpanelStore.mixpanelTrack({
      eventName: this.eventName,
      eventAction,
      eventActionDetail: actionDetail,
      properties: props,
      merchantUuid,
      streamUuid,
    });
  }

  chatMessageSent(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    message: string,
    userName: string,
  ) {
    if (message.length < config.MinMessageLength) return;

    const props = {
      [config.EventKeys.IsAdmin]: isAdmin,
      [config.EventKeys.Chat.MessageText]: message,
      [config.EventKeys.Chat.UserName]: userName,
    };

    this.track(
      config.EventActions.Send,
      config.EventActionsDetail.Chat.Message,
      merchantUuid,
      streamUuid,
      props,
    );
    mixpanelStore.peopleIncrement(config.EventActionsDetail.Chat.Message, 1);
  }

  chatQuestionSent(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    message: string,
    userName: string,
  ) {
    if (message.length < config.MinMessageLength) return;

    const props = {
      [config.EventKeys.IsAdmin]: isAdmin,
      [config.EventKeys.Chat.MessageText]: message,
      [config.EventKeys.Chat.UserName]: userName,
    };

    this.track(
      config.EventActions.Send,
      config.EventActionsDetail.Chat.Question,
      merchantUuid,
      streamUuid,
      props,
    );
    mixpanelStore.peopleIncrement(config.EventActionsDetail.Chat.Question, 1);
  }

  productCarouselDetailClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.Detail,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  productCarouselBuyNowClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.CarouselBuyNow,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  productDetailBuyNowClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.DetailBuyNow,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  productCarouselCartAddClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.CarouselCartAdd,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  productDetailCartAddClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.DetailCartAdd,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  productDetailToggleStatusClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.DetailToggleStatus,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  shoppingCartIncreaseQtyClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.ShoppingCartIncreaseQty,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  shoppingCartReduceQtyClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    const props = this.setProductInfo(
      isAdmin,
      productName,
      productPrice,
      productImage,
      productGateway,
    );

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.ShoppingCartReduceQty,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  shoppingCartCheckoutClick(
    merchantUuid: string,
    streamUuid: string,

    isAdmin: boolean,
    productNames: string[],
    totalPrice: string,
  ) {
    const props = {
      [config.EventKeys.IsAdmin]: isAdmin,
      [config.EventKeys.Product.Names]: productNames.join(', '),
      [config.EventKeys.Product.TotalPrice]: totalPrice,
    };

    this.track(
      config.EventActions.Click,
      config.EventActionsDetail.Product.ShoppingCartCheckoutClick,
      merchantUuid,
      streamUuid,
      props,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  setProductInfo(
    isAdmin: boolean,
    productName: string,
    productPrice: string,
    productImage: string,
    productGateway: string,
  ) {
    return {
      [config.EventKeys.IsAdmin]: isAdmin,
      [config.EventKeys.Product.Name]: productName,
      [config.EventKeys.Product.Price]: productPrice,
      [config.EventKeys.Product.Image]: productImage,
      [config.EventKeys.Product.Gateway]: productGateway,
    };
  }

  methods() {
    return {
      chatMessageSent: this.chatMessageSent,
      chatQuestionSent: this.chatQuestionSent,

      productCarouselDetailClick: this.productCarouselDetailClick,
      productCarouselBuyNowClick: this.productCarouselBuyNowClick,
      productCarouselCartAddClick: this.productCarouselCartAddClick,

      productDetailBuyNowClick: this.productDetailBuyNowClick,
      productDetailToggleStatusClick: this.productDetailToggleStatusClick,
      productDetailCartAddClick: this.productDetailCartAddClick,

      shoppingCartIncreaseQtyClick: this.shoppingCartIncreaseQtyClick,
      shoppingCartReduceQtyClick: this.shoppingCartReduceQtyClick,
      shoppingCartCheckoutClick: this.shoppingCartCheckoutClick,
    };
  }
}

export default BroadcastLive;
