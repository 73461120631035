import { RouteRecordRaw } from 'vue-router';
import userSettingsRoutes from '@/router/user/settings';
import userBioRoutes from '@/router/user/bio';

const routes: Array<RouteRecordRaw> = [
  ...userSettingsRoutes,
  ...userBioRoutes,
];

export default routes;
