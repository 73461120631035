class Pagination {
  total: number;

  perPage: number;

  currentPage: number;

  lastPage: number;

  constructor({
    total,
    perPage,
    currentPage,
    lastPage,
  }: {
    total: number
    perPage: number
    currentPage: number
    lastPage: number
  }) {
    this.total = total;
    this.perPage = perPage;
    this.currentPage = currentPage;
    this.lastPage = lastPage;
  }
}

export default Pagination;
