import Product from '@/models/user/bio/product';

class Store {
  id: string;

  name: string;

  layout: string; // grid, list

  products: Product[] | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.id = data.id;
    this.name = data.name || data.title;
    this.layout = data.layout;
    this.products = data.products?.length > 0
      ? data.products?.map((product: Product) => new Product(product)) : [];
  }

  getName(): string {
    return this.name;
  }
}

export default Store;
