class RealTimeTokens {
  appId: string;

  publisherToken: string;

  subscriberToken: string;

  chatToken: string;

  merchantUuid: string;

  streamUuid: string;

  shortId: number;

  streamUri: string;

  userId: string;

  constructor({
    appId,
    publisherToken,
    subscriberToken,
    chatToken,
    merchantUuid,
    streamUuid,
    shortId,
    streamUri,
    userId,
  }: {
    appId: string;
    publisherToken: string;
    subscriberToken: string;
    chatToken: string;
    merchantUuid: string;
    streamUuid: string;
    shortId: number;
    streamUri: string;
    userId: string;
  }) {
    this.appId = appId;
    this.publisherToken = publisherToken;
    this.publisherToken = publisherToken;
    this.subscriberToken = subscriberToken;
    this.chatToken = chatToken;
    this.merchantUuid = merchantUuid;
    this.streamUuid = streamUuid;
    this.shortId = shortId;
    this.streamUri = streamUri;
    this.userId = userId;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): RealTimeTokens[] {
    if (rows.length === 0) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        v[camelCaseKey] = row[index];
      });
      return new RealTimeTokens(v);
    });
  }
}

export default RealTimeTokens;
