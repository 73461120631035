export default class Brand {
  id = null as number | null;

  active = null as boolean | null;

  featured = null as boolean | null;

  name = null as string | null;

  description = null as string | null;

  logoUrl = null as string | null;

  createdAt = {
    date: null as string | null,
    timezoneType: null as number | null,
    timezone: null as string | null,
  };

  updatedAt = {
    date: null as string | null,
    timezoneType: null as number | null,
    timezone: null as string | null,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    Object.assign(this, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromModelApiResponse(data: any) {
    if (data === null || data === undefined) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const brand = new Brand(data);
    return brand;
  }
}
