import mitt, { Emitter } from 'mitt';

// Define the custom events
type Events = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add your specific event types here
};

// Create a typed event bus using mitt
const eventBus: Emitter<Events> = mitt<Events>();

// Export the event bus for global usage
export default eventBus;
