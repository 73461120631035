import { RouteRecordRaw } from 'vue-router';
import UserProfileManagerLayout from '@/components/layout/UserProfileManagerLayout.vue';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/profile/settings',
    name: 'UserProfileManagerPage',
    meta: {
      layout: UserProfileManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "userManager" */ '@/views/user/UserProfileManagerView.vue'),
  },
  {
    path: '/profile/settings/address',
    name: 'UserAddressesPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "userManager" */ '@/views/user/UserAddressesView.vue'),
  },
  {
    path: '/profile/settings/address/add',
    name: 'UserAddressCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "userManager" */ '@/views/user/UserAddressesCreateView.vue'),
  },
  {
    path: '/profile/settings/payment-methods',
    name: 'UserPaymentMethodsPage',
    meta: {
      layout: UserProfileManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "userManager" */ '@/views/user/UserPaymentMethodsView.vue'),
  },
  {
    path: '/profile/settings/notifications',
    name: 'UserNotificationsPage',
    meta: {
      layout: UserProfileManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "userManager" */ '@/views/user/UserNotificationsView.vue'),
  },
];

export default routes;
