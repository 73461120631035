import { RouteRecordRaw } from 'vue-router';
import BroadcastLiveLayout from '@/components/layout//broadcast/BroadcastLiveLayout.vue';
import BroadcastWatchLayout from '@/components/layout/broadcast/BroadcastWatchLayout.vue';
import BroadcastModerateLayout from '@/components/layout/broadcast/BroadcastModerateLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/streams/:uuid/broadcast/start',
    name: 'StreamBroadcastStartPage',
    meta: {
      layout: BroadcastLiveLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "liveStream" */ '@/views/stream/broadcast/BroadcastStart.vue'),
  },
  {
    path: '/streams/:uuid/broadcast/live',
    name: 'StreamBroadcastLivePage',
    meta: {
      layout: BroadcastLiveLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "liveStream" */ '@/views/stream/broadcast/BroadcastLive.vue'),
  },
  {
    path: '/streams/:uri/broadcast/watch',
    name: 'StreamBroadcastWatchPage',
    meta: {
      layout: BroadcastWatchLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "liveWatch" */ '@/views/stream/broadcast/BroadcastWatch.vue'),
  },
  {
    path: '/streams/:uuid/broadcast/moderate',
    name: 'StreamBroadcastModeratePage',
    meta: {
      layout: BroadcastModerateLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "liveModerate" */ '@/views/stream/broadcast/BroadcastModerate.vue'),
  },
];

export default routes;
