import { RouteRecordRaw } from 'vue-router';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';
import StreamManagerLayout from '@/components/layout/StreamManagerLayout.vue';
import StreamManagerProductsLayout from '@/components/layout/StreamManagerProductsLayout.vue';
import StreamManagerExternalProductsLayout from '@/components/layout/StreamManagerExternalProductsLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/streams/:uuid/settings',
    name: 'StreamManagerPage',
    meta: {
      layout: StreamManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/StreamManagerView.vue'),
  },
  {
    path: '/streams/:uuid/settings/faqs',
    name: 'MyStreamFAQPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/faq/FaqsView.vue'),
  },
  {
    path: '/streams/:uuid/settings/products/:brandId/group/:groupId',
    name: 'MyStreamProductsPage',
    meta: {
      layout: StreamManagerProductsLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/product/yampi/ProductsView.vue'),
  },
  {
    path: '/streams/:uuid/settings/products/external-link',
    name: 'StreamExternalLinkProductsPage',
    meta: {
      layout: StreamManagerExternalProductsLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/product/externalLink/ProductsView.vue'),
  },
  {
    path: '/streams/:uuid/settings/faqs/add',
    name: 'FAQCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/faq/CreateView.vue'),
  },
  {
    path: '/streams/:uuid/settings/faqs/:faqUuid/edit',
    name: 'FAQEditPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/faq/EditView.vue'),
  },
  {
    path: '/streams/:uuid/settings/admins',
    name: 'MyStreamAdminsPage',
    meta: {
      layout: StreamManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/admin/AdminsView.vue'),
  },
  {
    path: '/streams/:uuid/settings/admins/add',
    name: 'MyStreamCreateAdminPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/admin/CreateView.vue'),
  },
  {
    path: '/streams/:uuid/settings/description',
    name: 'MyStreamEditDescriptionPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "streamManager" */ '@/views/stream/manager/EditDescriptionView.vue'),
  },
];

export default routes;
