export default class SEO {
  seoTitle = null as string | null;

  seoDescription = null as string | null;

  seoKeywords = null as string | null;

  canonicalUrl = null as string | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    Object.assign(this, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromModelApiResponse(row: any) {
    if (row === null || row === undefined) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const v: any = {};
    Object.keys(row).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const camelCaseKey = key.replace(/_([a-z])/g, (_: any, letter: any) => letter.toUpperCase());
      v[camelCaseKey] = row[key];
    });
    return new SEO(v);
  }
}
