import { RouteRecordRaw } from 'vue-router';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';
import LoginView from '@/views/session/LoginView.vue';
import SignupView from '@/views/session/SignupView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/session/login',
    name: 'SessionLoginPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
    },
    component: LoginView,
  },
  {
    path: '/session/refresh',
    name: 'SessionLoginRefreshPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/session/LoginRefreshView.vue'),
  },
  {
    path: '/session/signup',
    name: 'SessionSignupPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
    },
    component: SignupView,
  },
  {
    path: '/session/password/reset',
    name: 'SessionPasswordResetPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/session/PasswordResetView.vue'),
  },
  {
    path: '/session/recover',
    name: 'SessionRecoverPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/session/PasswordRecoverView.vue'),
  },
  {
    path: '/session/temporary/password',
    name: 'SessionTemporaryPasswordPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: false,
    },
    component: () => import(/* webpackChunkName: "session" */ '@/views/session/TempPasswordSent.vue'),
  },
];

export default routes;
