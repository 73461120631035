import { RouteRecordRaw } from 'vue-router';
import merchantSettings from '@/router/merchant/settings';
import merchantProducts from '@/router/merchant/product';
import merchantReports from '@/router/merchant/reports';

const routes: Array<RouteRecordRaw> = [
  ...merchantProducts,
  ...merchantSettings,
  ...merchantReports,
];

export default routes;
