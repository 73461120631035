class ExternalProduct {
  id = null as string | null;

  uuid = null as string | null;

  createdAt = null as Date | null;

  updatedAt = null as Date | null;

  deletedAt = null as Date | null;

  createdByUuid = null as string | null;

  merchantUuid = null as string | null;

  gateway = null as string | null;

  gatewayProductId = null as string | null;

  title = null as string | null;

  description = null as string | null;

  images = {
    cdnUrl: null as string | null,
    count: 0,
  };

  priceCurrency = null as string | null;

  priceSale = null as number | null;

  priceDiscount = null as number | null;

  link = null as string | null;

  active = null as boolean | null;

  constructor({
    id,
    uuid,
    createdAt,
    updatedAt,
    deletedAt,
    createdByUuid,
    merchantUuid,
    gateway,
    gatewayProductId,
    title,
    description,
    images,
    priceCurrency,
    priceSale,
    priceDiscount,
    link,
    active,
  }: {
    id: string,
    uuid: string,
    createdAt: Date,
    updatedAt: Date,
    deletedAt: Date,
    createdByUuid: string,
    merchantUuid: string,
    gateway: string,
    gatewayProductId: string,
    title: string,
    description: string,
    images: {
      cdnUrl: string,
      count: number,
    },
    priceCurrency: string,
    priceSale: number,
    priceDiscount: number,
    link: string,
    active: boolean,
  }) {
    this.id = id;
    this.uuid = uuid;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.createdByUuid = createdByUuid;
    this.merchantUuid = merchantUuid;
    this.gateway = gateway;
    this.gatewayProductId = gatewayProductId;
    this.title = title;
    this.description = description;
    this.images = {
      cdnUrl: images.cdnUrl,
      count: images.count,
    };
    this.priceCurrency = priceCurrency;
    this.priceSale = priceSale;
    this.priceDiscount = priceDiscount;
    this.link = link;
    this.active = active;
  }

  getCheckoutUrl(): string {
    return this.link || '';
  }

  static getImagesTotalFromUrl(text: string) {
    const [, count] = text.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ').split('~');
    if (!count) {
      return 0;
    }
    return parseInt(count.replace(/\D/g, ''), 10);
  }

  getImageUrl(modifiers: string): (string | null) {
    let mod = modifiers;
    if (mod === undefined) {
      mod = '';
    }
    return this.getAllImagesUrl(mod)[0];
  }

  getAllImagesUrl(modifiers: string): (string | null)[] {
    let mod = modifiers;
    if (mod === undefined) {
      mod = '';
    }
    const count = ExternalProduct.getImagesTotalFromUrl(this.images.cdnUrl || '');
    const cdn = this.images.cdnUrl || '';
    if (!cdn) {
      return [];
    }
    const urls = [];
    for (let index = 0; index < count; index += 1) {
      urls.push(`${cdn}nth/${index}/${mod}-/preview/-/format/jpg/?.jpg`);
    }
    return urls;
  }

  getName(): string {
    return this.title || '';
  }

  // eslint-disable-next-line class-methods-use-this
  formattedAvailableQuantity(): string {
    return 'ilimitado';
  }

  getFormattedPrice(): string {
    const priceSale = this.priceSale?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    if (
      this.priceDiscount && this.priceDiscount > 0) {
      const priceDiscount = this.priceDiscount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return `<span class='line-through'>${priceSale}</span> ${priceDiscount}`;
    }

    if (this.priceSale === 0.0) {
      return '<strong>Grátis</strong>';
    }

    return priceSale || '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): ExternalProduct[] {
    if (rows.length === 0) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (
          row[index]
          && (
            camelCaseKey === 'updatedAt'
            || camelCaseKey === 'createdAt'
            || camelCaseKey === 'deletedAt'
          )
        ) {
          v[camelCaseKey] = new Date(row[index]);
        } else if (camelCaseKey === 'uuid') {
          v[camelCaseKey] = row[index];
          v.id = row[index];
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new ExternalProduct(v);
    });
  }
}

export default ExternalProduct;
