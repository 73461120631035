import { RouteRecordRaw } from 'vue-router';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';
import MerchantManagerProductsLayout from '@/components/layout/yampi/MerchantManagerProductsLayout.vue';
import MerchantManagerExternalLinkProductsLayout from '@/components/layout/MerchantManagerExternalLinkProductsLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/merchants/:uuid/settings/products/external-link',
    name: 'MyMerchantProductsExternalLinkPage',
    meta: {
      layout: MerchantManagerExternalLinkProductsLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantProductManager" */ '@/views/merchant/manager/product/externalLink/ProductsView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/products/external-link/new',
    name: 'MyMerchantProductsExternalLinkCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantProductManager" */ '@/views/merchant/manager/product/externalLink/CreateView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/products/:productUuid/external-link/edit',
    name: 'MyMerchantProductsExternalLinkEditPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantProductManager" */ '@/views/merchant/manager/product/externalLink/EditView.vue'),
  },

  {
    path: '/merchants/:uuid/settings/products/brand/:brandId',
    name: 'MyMerchantProductsPage',
    meta: {
      layout: MerchantManagerProductsLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantProductManager" */ '@/views/merchant/manager/product/yampi/ProductsView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/products/brand/:brandId/new',
    name: 'MyMerchantProductsCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantProductManager" */ '@/views/merchant/manager/product/yampi/CreateView.vue'),
  },
  {
    path: '/merchants/:uuid/settings/products/brand/:brandId/:productId/edit',
    name: 'MyMerchantProductsEditPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantProductManager" */ '@/views/merchant/manager/product/yampi/EditView.vue'),
  },
];

export default routes;
