class ShopifyOauth {
  code: string;

  hmac: string;

  host: string;

  shop: string;

  state: string;

  timestamp: string;

  constructor({
    code,
    hmac,
    host,
    shop,
    state,
    timestamp,
  }: {
    code: string;
    hmac: string;
    host: string;
    shop: string;
    state: string;
    timestamp: string;
  }) {
    this.code = code;
    this.hmac = hmac;
    this.host = host;
    this.shop = shop;
    this.state = state;
    this.timestamp = timestamp;
  }
}

export default ShopifyOauth;
