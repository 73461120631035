import { useCookies } from 'vue3-cookies';

export default function useUserCookies() {
  const { cookies } = useCookies();
  const getLanguage = () => cookies.get('Language') || 'en-US';
  const getTimezone = () => 'America/Sao_Paulo';
  // const getTimezone = () => {
  //   const localDate = new Date().toString().match(/([A-Z]+[+-][0-9]+.*)/);
  //   if (localDate) {
  //     return localDate[1].split(' ')[0];
  //   }
  //   return 'GMT';
  // };

  return {
    getLanguage,
    getTimezone,
  };
}
