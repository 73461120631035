class Image {
  uuid: string;

  cdnUrl: string;

  cdnUrlModifiers: string;

  mimeType: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.uuid = data.uuid && data.uuid !== '00000000-0000-0000-0000-000000000000' ? data.uuid : '';
    this.cdnUrl = data.cdnUrl || data.cdn_url;
    this.cdnUrlModifiers = data.cdnUrlModifiers || data.cdn_url_modifiers;
    this.mimeType = data.mimeType || data.mime_type;
  }

  static newImage(): Image {
    return new Image({
      uuid: '',
      cdnUrl: '',
      cdnUrlModifiers: '',
      mimeType: '',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiModel(row: any): Image {
    return new Image({
      uuid: row.uuid,
      cdnUrl: row.cdn_url,
      cdnUrlModifiers: row.cdn_url_modifiers,
      mimeType: row.mime_type,
    });
  }
}

export default Image;
