import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import Plugin from '@/models/plugin/plugin';
import Pagination from '@/models/pagination';
import eventBus from '@/eventBus/eventBus';

export default defineStore('pluginStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const pluginStore = ref({
    crsf: '',
    plugins: {
      data: [] as Plugin[],
      pagination: {} as Pagination,
    },
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('pluginStore');
  };

  const pluginsDoGetMy = async () => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    return axios.get('/plugins/my', config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          pluginStore.value.plugins.data = [] as Plugin[];
          pluginStore.value.plugins.pagination = {} as Pagination;
          return pluginStore.value.plugins;
        }

        const { columns, rows, pagination } = response.data;
        const plugins = Plugin.fromApiResponse(columns, rows);

        if (plugins.length === 0) {
          return pluginStore.value.plugins;
        }

        pluginStore.value.plugins.data = plugins;
        pluginStore.value.plugins.pagination = pagination;
        return pluginStore.value.plugins;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsDoDelete = async (id: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };
    const payload = {
      id,
    };

    return axios.post('/plugins/delete', payload, config)
      .then((response) => {
        if (response.status !== 200) {
          return undefined;
        }
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsDoCreate = async (plugin: Plugin) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };
    const payload = {
      name: plugin.name,
      type: plugin.type,
      redirect_uri: plugin.redirectURI,
      version: plugin.version,
      token: plugin.token,
      owner_uuid: plugin.ownerUuid,
      admins_uuid: plugin.adminsUuid,
      shopify_oauth: plugin.shopifyOauth ? {
        code: plugin.shopifyOauth.code,
        hmac: plugin.shopifyOauth.hmac,
        host: plugin.shopifyOauth.host,
        shop: plugin.shopifyOauth.shop,
        state: plugin.shopifyOauth.state,
        timestamp: plugin.shopifyOauth.timestamp,
      } : null,
    };

    return axios.post('/plugins/add', payload, config)
      .then((response) => {
        if (response.status !== 200) {
          return undefined;
        }
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const pluginsProcessInstagramToken = async (
    provider: string,
    code: string,
    scope: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      provider,
      code,
      scope,
    };

    return axios.post(
      '/plugins/oauth/exchange',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch((error) => {
      console.log('error', error);
      throw error;
    });
  };

  /*
    Hooks
  */
  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('pluginStore')) {
    pluginStore.value = JSON.parse(localStorage.getItem('pluginStore') || '{}');
  }

  watch(pluginStore, (pluginsVal) => {
    localStorage.setItem('pluginStore', JSON.stringify(pluginsVal));
  }, {
    deep: true,
  });

  return {
    pluginStore,
    resetStore,
    pluginsDoGetMy,
    pluginsDoCreate,
    pluginsDoDelete,
    pluginsProcessInstagramToken,
  };
});
