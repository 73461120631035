export default class SearchAttributes {
  hasSkus = null as boolean | null;

  hasCategories = null as boolean | null;

  hasImages = null as boolean | null;

  categoriesIds = null as number[] | null;

  categoriesNames = null as string[] | null;

  filtersIds = null as number[] | null;

  flagsIds = null as number[] | null;

  collectionsIds = null as number[] | null;

  totalOrders = null as number | null;

  sku = null as string | null;

  searchTerms = null as string[] | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    Object.assign(this, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromModelApiResponse(data: any) {
    if (data === null || data === undefined) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const searchAttributes = new SearchAttributes(data);
    return searchAttributes;
  }
}
