export default class Extras {
  ncm = null as string | null;

  googleCategory = null;

  video = null as string | null;

  facebookPixelId = null;

  searchTerms = null;

  totalComments = null as number | null;

  totalReviews = null as number | null;

  totalOrders = null as number | null;

  totalInStock = null as number | null;

  forGift = null as boolean | null;

  useDifferentImages = null as boolean | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    Object.assign(this, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromModelApiResponse(row: any) {
    if (row === null || row === undefined) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const v: any = {};
    Object.keys(row).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const camelCaseKey = key.replace(/_([a-z])/g, (_: any, letter: any) => letter.toUpperCase());
      v[camelCaseKey] = row[key];
    });
    return new Extras(v);
  }
}
