import { RouteRecordRaw } from 'vue-router';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/merchants/:uuid/reports/invoices',
    name: 'MyMerchantReportInvoicesPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "merchantReports" */ '@/views/merchant/manager/reports/InvoicesView.vue'),
  },
];

export default routes;
