import { RouteRecordRaw } from 'vue-router';
import StreamLandingPageLayout from '@/components/layout/StreamLandingPageLayout.vue';
import StreamRecordingsPageLayout from '@/components/layout/StreamRecordingsPageLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/lives/:uri/countdown',
    name: 'StreamLandingCountdownPage',
    meta: {
      layout: StreamLandingPageLayout,
      accessWithSession: undefined,
    },
    component: () => import(
      /* webpackChunkName: "streamLanding" */ '@/views/stream/broadcast/CountdownView.vue'
    ),
  },
  {
    path: '/lives/:uri/finished',
    name: 'StreamLandingFinishedPage',
    meta: {
      layout: StreamLandingPageLayout,
      accessWithSession: undefined,
    },
    component: () => import(
      /* webpackChunkName: "streamLanding" */ '@/views/stream/broadcast/FinishedView.vue'
    ),
  },
  {
    path: '/lives/:uri',
    name: 'StreamLandingPage',
    meta: {
      layout: StreamLandingPageLayout,
      accessWithSession: undefined,
    },
    component: () => import(
      /* webpackChunkName: "streamLanding" */ '@/views/stream/broadcast/LandingView.vue'
    ),
  },
  {
    path: '/lives/:uri/recordings',
    name: 'StreamRecordingsPage',
    meta: {
      layout: StreamRecordingsPageLayout,
      accessWithSession: undefined,
    },
    component: () => import(
      /* webpackChunkName: "streamLanding" */ '@/views/stream/broadcast/StreamRecordingsPageView.vue'
    ),
  },
];

export default routes;
