import { RouteRecordRaw } from 'vue-router';
import DashboardLayout from '@/components/layout/DashboardLayout.vue';

const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: '/dashboard',
    name: 'DashboardPage',
    meta: {
      label: 'Dashboard',
      layout: DashboardLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/DashboardView.vue'),
  },
];

export default dashboardRoutes;
