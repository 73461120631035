import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import User from '@/models/user';
import UserBio from '@/models/user/bio/userBio';
import eventBus from '@/eventBus/eventBus';

export default defineStore('userStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const userStore = ref({
    myProfile: {} as User,
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('userStore');
    // userStore.value = JSON.parse(localStorage.getItem('userStore') || '{}');
  };

  // Get current user profile informations
  const usersDoLoadBio = async (username: string) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const uname = username.replace('@', '');

    return axios.get(`/users/bio/@${uname}`, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }

        const { columns, row } = response.data;
        const [userBio] = UserBio.fromApiResponse(columns, [row]);
        return userBio;
      })
      .catch((error) => {
        console.log('error', error);
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const usersDoGetMyUser = async () => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    return axios.get('/users/profiles/my', config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          userStore.value.myProfile = {} as User;
          return undefined;
        }

        const { columns, row } = response.data;
        const user = User.fromApiResponse(columns, [row]);

        [userStore.value.myProfile] = user;
        return userStore.value.myProfile;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const usersDoUpdateMyUser = async (params: any) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      language: params.language,
      timezone: params.timezone,
      name: params.name,
      birthdate: params.birthdate,
      document: params.document,
      document_type: params.documentType,
      email: params.email,
      mobile: params.mobile,
      facebook: params.facebook,
      linkedin: params.linkedin,
      instagram: params.instagram,
      tiktok: params.tiktok,
      youtube: params.youtube,
      profile_image: params.profileImage,
      password: params.password,
      new_password: params.newPassword,
      username: params.username,
    };

    return axios.post('/users/profiles/my/update', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }
        const { message, columns, row } = response.data;
        const user = User.fromApiResponse(columns, [row]);

        [userStore.value.myProfile] = user;
        return message;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  const usersDoUpdateMyBio = async (
    params: UserBio,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      id: params.id,
      username: params.username.toLowerCase(),
      style: params.style,
      live: params.live,
      profile: params.profile,
      links: params.links,
    };

    return axios.post('/users/bio', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }
        return true;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  /*
    Hooks
  */
  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('userStore')) {
    userStore.value = JSON.parse(localStorage.getItem('userStore') || '{}');
  }

  watch(userStore, (usersVal) => {
    localStorage.setItem('userStore', JSON.stringify(usersVal));
  }, {
    deep: true,
  });

  return {
    userModel: User,
    userStore,
    resetStore,
    usersDoLoadBio,
    usersDoGetMyUser,
    usersDoUpdateMyUser,
    usersDoUpdateMyBio,
  };
});
