export default class Image {
  id = null as number | null;

  processed = null as boolean | null;

  name = null as string | null;

  order = null as number | null;

  extension = null as string | null;

  filterImageUrl = null as string | null;

  small = {
    width: null as number | null,
    height: null as number | null,
    url: null as string | null,
  };

  thumb = {
    width: null as number | null,
    height: null as number | null,
    url: null as string | null,
  };

  medium = {
    width: null as number | null,
    height: null as number | null,
    url: null as string | null,
  };

  large = {
    width: null as number | null,
    height: null as number | null,
    url: null as string | null,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    Object.assign(this, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(data: any[]) {
    if (data.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.map((row: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      Object.keys(row).forEach((key: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const camelCaseKey = key.replace(/_([a-z])/g, (_: any, letter: any) => letter.toUpperCase());
        v[camelCaseKey] = row[key];
      });
      return new Image(v);
    });
  }
}
