import Image from '@/models/image';

class Bio {
  bgHex: string;

  bgImg: Image;

  colorHex: string;

  font: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.bgHex = data.bgHex || data.bg_hex;
    this.bgImg = new Image(data.bgImg || data.bg_img);
    this.colorHex = data.colorHex || data.color_hex;
    this.font = data.font;
  }

  static newBio(): Bio {
    return new Bio({
      bgHex: '#000000',
      bgImg: Image.newImage(),
      colorHex: '#ffffff',
      font: 'Arial',
    });
  }
}

export default Bio;
