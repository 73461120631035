import mixpanelStore from '@/store/mixpanel/mixpanel';
import {
  RouteRecordName,
} from 'vue-router';

const config = {
  EventName: 'Page View',
  EventActions: {
    View: 'View',
  },
  EventActionsDetail: {
    PageAutoTrack: 'Page Auto Track',
  },
  EventKeys: {
    FromPage: 'From Page',
    ToPage: 'To Page',
    Path: 'Path',
  },
};

class PageView {
  eventName: string;

  constructor() {
    this.eventName = config.EventName;
  }

  track(
    actionDetail: string,

    props: Record<string, string | boolean | RouteRecordName | null | undefined>,
  ) {
    const merchantUuid = null;
    const streamUuid = null;

    const properties = {
      ...props,
    };

    mixpanelStore.mixpanelTrack({
      eventName: this.eventName,
      eventAction: config.EventActions.View,
      eventActionDetail: actionDetail,
      properties,
      merchantUuid,
      streamUuid,
    });
    mixpanelStore.peopleIncrement(config.EventName, 1);
  }

  trackPageView(
    fromPage: RouteRecordName | string | null | undefined,
    toPage: RouteRecordName | string | null | undefined,
    path: string,
  ) {
    const props = {
      [config.EventKeys.FromPage]: fromPage,
      [config.EventKeys.ToPage]: toPage,
      [config.EventKeys.Path]: path,
    };

    this.track(config.EventActionsDetail.PageAutoTrack, props);
  }

  methods() {
    return {
      trackPageView: this.trackPageView, // WORKING!
    };
  }
}

export default PageView;
