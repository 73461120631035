import { RouteRecordRaw } from 'vue-router';
import UserBioManagerLayout from '@/components/layout/UserBioManagerLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/bio',
    name: 'UserBioManagerPage',
    meta: {
      layout: UserBioManagerLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "bio" */ '@/views/user/bio/ManagerView.vue'),
  },
];

export default routes;
