class Price {
  sale = 0.0;

  discount = 0.0;

  currency: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.sale = data.sale;
    this.discount = data.discount;
    this.currency = data.currency;
  }

  getFormattedPrice(): string {
    if (
      (this.discount === null || this.discount === 0)
      && (this.sale === null || this.sale === 0)
    ) {
      return '<strong>Grátis</strong>';
    }
    const sale = this.sale?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    if (
      this.discount && this.discount > 0
      && this.sale && this.discount < this.sale
    ) {
      const discount = this.discount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return `<span class='line-through'>${sale}</span> ${discount}`;
    }
    return sale || '';
  }
}

export default Price;
