class InstagramOauth {
  id: string;

  username: string;

  accountType: string;

  code: string;

  constructor({
    id,
    username,
    accountType,
    code,
  }: {
    id: string;
    username: string;
    accountType: string;
    code: string;
  }) {
    this.id = id;
    this.username = username;
    this.accountType = accountType;
    this.code = code;
  }
}

export default InstagramOauth;
