import Variations from '@/models/yampi/product/variations';

export default class Sku {
  id = null as number | null;

  productId = null as number | null;

  sellerId = null as number | null;

  sku = null as string | null;

  token = '';

  erpId = null as number | null;

  blockedSale = false;

  barcode = null as string | null;

  title = null as string | null;

  availability = 1;

  availabilitySoldout = -1;

  daysAvailabilityFormated = null as string | null;

  priceCost = 0.0;

  priceSale = 0.0;

  priceDiscount = 0.0;

  width = 1;

  height = 1;

  length = 1;

  weight = 1;

  quantityManaged = false;

  stockQuantity = 0;

  variations = [] as Variations[];

  combinations = null as string | null;

  order = null as number | null;

  totalInStock = null as number | null;

  allowSellWithoutCustomization = null as boolean | null;

  imageReferenceSkuId = null as number | null;

  purchaseUrl = null as string | null;

  createdAt = {
    date: null as string | null,
    timezoneType: null as number | null,
    timezone: null as string | null,

  };

  updatedAt = {
    date: null as string | null,
    timezoneType: null as number | null,
    timezone: null as string | null,
  };

  images = [] as { url: string | null }[];

  variationsValuesIds = [] as number[];
  // customizations: {
  //   data: any[]; // You may specify a type for this property
  // };

  constructor(data: Sku) {
    Object.assign(this, data);
    if (this.sku === null || this.sku === '') {
      this.sku = this.generateSKUToken();
      this.token = this.generateSKUToken();
    }

    // If you want to parse the nested customizations property as well, you can do so here.
    // this.customizations.data = this.customizations.data.map((customizationData: any) => {

    //   // Parse customization data if needed
    //   return customizationData;
    // });
  }

  generateSKUToken(): string {
    if (this.token !== '') {
      return this.token;
    }
    const len = 10;
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = len; i > 0; i -= 1) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  getCheckoutUrl(streamUuid: string, merchantUuid: string, clickedContent: string): string | null {
    const queryParams = `?utm_campaign=${streamUuid}&utm_source=${merchantUuid}&utm_medium=live&utm_content=${clickedContent}`;
    return `${this.purchaseUrl}${queryParams}`;
  }

  getFormattedVariantions(): string | null {
    if (this.variations.length === 0) {
      return null;
    }
    return this.variations.map((v) => `${v.name}: ${v.value}`).join(' / ');
  }

  getPrice(): number | null {
    if (this.priceDiscount !== null && this.priceDiscount !== 0) {
      return this.priceDiscount;
    }
    return this.priceSale;
  }

  getFormattedPrice(): string {
    if (
      (this.priceDiscount === null || this.priceDiscount === 0)
      && (this.priceSale === null || this.priceSale === 0)
    ) {
      return '<strong>Grátis</strong>';
    }
    const priceSale = this.priceSale?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    if (
      this.priceDiscount && this.priceDiscount > 0
      && this.priceSale && this.priceDiscount < this.priceSale
    ) {
      const priceDiscount = this.priceDiscount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      return `<span class='line-through'>${priceSale}</span>  ${priceDiscount}`;
    }
    return priceSale || '';
  }

  getFormattedPriceCost(): string | null {
    if (this.priceCost === null) {
      return null;
    }
    return this.priceCost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  getFormattedPriceSale(): string | null {
    if (this.priceSale === null) {
      return null;
    }
    return this.priceSale.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  getFormattedPriceDiscount(): string | null {
    if (this.priceDiscount === null) {
      return null;
    }
    return this.priceDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  getFormattedWeight(): string {
    return `${this.weight} kg`;
  }

  getFormattedMeasures(): string {
    return `${this.width}cm x ${this.height}cm x ${this.length}cm`;
  }

  getTitle(): string {
    if (this.title === null) {
      return '';
    }
    return this.title?.replace(/ ref\. \d+ ?/g, ' ');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromModelApiResponse(data: any[]) {
    if (data.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.map((row: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      Object.keys(row).forEach((key: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const camelCaseKey = key.replace(/_([a-z])/g, (_: any, letter: any) => letter.toUpperCase());
        if (camelCaseKey === 'variations') {
          v[camelCaseKey] = Variations.fromModelApiResponse(row[key]);
          v.variationsValuesIds = v[camelCaseKey].map((variation: Variations) => variation.valueId);
        } else {
          v[camelCaseKey] = row[key];
        }
      });
      return new Sku(v);
    });
  }
}
