class ContactUs {
  supportType: string;

  senderName: string;

  senderContact: string;

  senderContactType: string;

  senderMessage: string;

  constructor({
    supportType,
    senderName,
    senderContact,
    senderContactType,
    senderMessage,
  }: {
    supportType: string;
    senderName: string;
    senderContact: string;
    senderContactType: string;
    senderMessage: string;
  }) {
    this.supportType = supportType;
    this.senderName = senderName;
    this.senderContact = senderContact;
    this.senderContactType = senderContactType;
    this.senderMessage = senderMessage;
  }
}

export default ContactUs;
