import { RouteRecordRaw } from 'vue-router';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';
import streamSettingsRoutes from '@/router/stream/settings';
import streamBroadcastRoutes from '@/router/stream/broadcast';
import streamLandingPageRoutes from '@/router/stream/landingPage';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/streams',
    name: 'MyStreamsPage',
    meta: {
      label: 'MyStreamsPage',
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(
      /* webpackChunkName: "stream" */ '@/views/stream/MyStreamsView.vue'
    ),
  },
  {
    path: '/merchants/:merchantUuid/streams',
    name: 'MyMerchantStreamsPage',
    meta: {
      label: 'MyMerchantStreamsPage',
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(
      /* webpackChunkName: "stream" */ '@/views/stream/MyMerchantStreamsView.vue'
    ),
  },
  {
    path: '/merchants/:merchantUuid/streams/new',
    name: 'MyMerchantStreamCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "stream" */ '@/views/stream/MyMerchantStreamCreateView.vue'),
  },
  {
    path: '/streams/new',
    name: 'StreamCreatePage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: true,
    },
    component: () => import(/* webpackChunkName: "stream" */ '@/views/stream/StreamCreateView.vue'),
  },
  ...streamLandingPageRoutes,
  ...streamBroadcastRoutes,
  ...streamSettingsRoutes,
];

export default routes;
