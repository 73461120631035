import Image from '@/models/image';
import Live from '@/models/user/bio/live';
import Store from '@/models/user/bio/store';
import Merchant from '@/models/merchant';
import Product from '@/models/user/bio/product';

class Link {
  id: number | string | null;

  type: string;

  header: string;

  url: string;

  order: number;

  active: boolean;

  img: Image | null;

  stores: Store[] | null;

  live: Live | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.id = data.id ? data.id : Link.generateId();
    this.type = data.type;
    this.header = data.header;
    this.url = data.url;
    this.order = data.order;
    this.active = data.active;
    this.stores = data.type === 'store' && data.stores?.length > 0
      ? data.stores.map((store: Store) => new Store(store)) : null;
    this.img = data.img ? new Image(data.img) : null;
    this.live = data.type === 'live' ? new Live(data.live) : null;
  }

  validUrl(): boolean {
    if (
      this.isYoutube()
      || this.isSpotfy()
      || this.isInstagram()
      || this.isFacebook()
      || this.isTiktok()
    ) {
      return this.url.includes('<iframe ') || this.url.includes('<blockquote ');
    }
    if (this.isLive()) {
      return this.url.includes('livelink.com.br ') || this.url.includes('/broadcast/watch');
    }
    if (this.isStore()) {
      return this.url.length > 0
        ? this.url.includes('http://') || this.url.includes('https://') : true;
    }
    return this.url.includes('http://') || this.url.includes('https://');
  }

  extractUri = (): string | null => {
    try {
      const urlObj = new URL(this.url);

      const pathSegments = urlObj.pathname.split('/broadcast/watch').filter((part) => part.trim() !== '');
      if (pathSegments.length === 0) return null;

      const uri = pathSegments[0].split('/streams/').filter((part) => part.trim() !== '');
      return uri[0];
    } catch (error) {
      console.error('Error parsing URL:', error);
      return null; // Return null if the URL is invalid
    }
  };

  getIconName(): string[] {
    switch (this.type) {
      case 'link':
        return ['fas', 'link'];
      case 'store':
        return ['fas', 'tags'];
      case 'live':
        return ['fas', 'video'];
      case 'youtube':
        return ['fab', 'youtube'];
      case 'spotify':
        return ['fab', 'spotify'];
      case 'instagram':
        return ['fab', 'instagram'];
      case 'tiktok':
        return ['fab', 'tiktok'];
      case 'facebook':
        return ['fab', 'facebook'];
      default:
        return ['fas', 'link'];
    }
  }

  isLink(): boolean {
    return this.type === 'link';
  }

  isLive(): boolean {
    return this.type === 'live';
  }

  isStore(): boolean {
    return this.type === 'store';
  }

  isYoutube(): boolean {
    return this.type === 'youtube';
  }

  isSpotfy(): boolean {
    return this.type === 'spotify';
  }

  isInstagram(): boolean {
    return this.type === 'instagram';
  }

  isFacebook(): boolean {
    return this.type === 'facebook';
  }

  isTiktok(): boolean {
    return this.type === 'tiktok';
  }

  getProductsIds(): number[] {
    if (!this.isStore() || !this.stores || this.stores.length === 0) return [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.stores.reduce((acc: any, store) => {
      if (!store.products) return acc;
      return acc.concat(store.products.map((product) => product.id));
    }, []);
  }

  removeProductFromStore(product: Product) {
    if (!this.isStore() || !this.stores || this.stores.length === 0) return;
    this.stores.forEach((store) => {
      if (!store.products) return;
      const productIndex = store.products.findIndex((p) => `${p.id}` === `${product.id}`);
      if (productIndex >= 0) store.products.splice(productIndex, 1);
    });
  }

  toggleStoreProduct(m: Merchant, p: Product, allowRemove: boolean) {
    if (!this.isStore()) return;
    if (!this.stores) this.stores = [];
    const storeIndex = this.stores.findIndex((st) => `${st.id}` === `${m.uuid}`);

    // new store
    if (storeIndex === undefined || storeIndex < 0) {
      this.stores.push(new Store({
        id: `${m.uuid}`,
        name: m.name,
        layout: 'grid',
        products: [p],
      }));
      return;
    }
    const productIndex = this.stores[storeIndex].products?.findIndex((pr) => pr.id === p.id);
    // new product
    if (productIndex === undefined || productIndex < 0) {
      this.stores[storeIndex].products?.push(p);
      return;
    }

    if (!allowRemove) return;

    // remove product
    this.stores[storeIndex].products?.splice(productIndex, 1);

    // remove store if empty
    if (this.stores[storeIndex].products?.length === 0) {
      this.stores.splice(storeIndex, 1);
    }
  }

  getName(): string {
    switch (this.type) {
      case 'link':
        return 'Link';
      case 'store':
        return 'Lojinha';
      case 'live':
        return 'Live';
      case 'youtube':
        return 'Youtube';
      case 'spotify':
        return 'Spotify';
      case 'instagram':
        return 'Instagram';
      case 'tiktok':
        return 'Tiktok';
      case 'facebook':
        return 'Facebook';
      default:
        return 'Link';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isProductInStore(productId: any): boolean {
    if (this.type !== 'store') return false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const productsList = this.stores?.reduce((acc: any, store) => {
      if (!store.products) return acc;
      return acc.concat(store.products);
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return productsList.length > 0 && productsList.some((product: any) => product.id === productId);
  }

  getProductsList() {
    if (!this.isStore() || this.stores?.length === 0) return [];
    return this.stores?.map((s) => s.products).flat();
  }

  getFormattedLiveDate(): string {
    if (!this.live?.date) return '';
    return `${this.live.date.getDate()} de ${this.live.date.toLocaleString('pt-BR', { month: 'long' })} de ${this.live?.date.getFullYear()} às ${this.live?.date.getHours().toString().padStart(2, '0')}:${this.live?.date.getMinutes().toString().padStart(2, '0')}`;
  }

  getUuidFromUrl(): string | null {
    const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
    const match = this.url.match(uuidRegex);
    return match ? match[0] : null;
  }

  replaceIframeCss(): void {
    if (!this.url) return;
    if (this.isYoutube()
      || this.isSpotfy()
      || this.isInstagram()
      || this.isFacebook()
      || this.isTiktok()) {
      this.url = this.url
        .replace('<iframe ', '<iframe title="x" ') // tiktok
        .replace('class="tiktok-embed"', 'class="tiktok-embed tiktok-embed-custom"') // tiktok
        .replace('min-width:326px', 'min-width:100%') // instagram
        .replace('height="250"', 'height="900"'); // facebook
    }
  }

  static createLink(type: string): Link {
    return new Link({
      type,
      header: '',
      url: '',
      order: -1,
      active: true,
      img: null,
      products: [],
      live: null,
    });
  }

  static generateId(): number {
    return Math.floor(Math.random() * 1000000000000000);
  }

  static getFormattedDate(): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Date().toLocaleDateString('pt-BR', options);
  }

  getMiniDescription(): string {
    switch (this.type) {
      case 'link':
        return 'Um <strong>link</strong> para qualquer site';
      case 'store':
        return 'Sua <strong>lojinha</strong> da bio';
      case 'live':
        return 'Qual a sua próxima <strong>live</strong>?';
      case 'youtube':
        return 'Destacar um <strong>vídeo</strong> do Youtube';
      case 'spotify':
        return 'Destacar uma <strong>playlist</strong> do Spotify';
      case 'instagram':
        return 'Destacar uma <strong>postagem</strong> do Instagram';
      case 'tiktok':
        return 'Destacar um <strong>vídeo</strong> do Tiktok';
      case 'facebook':
        return 'Destacar um <strong>vídeo</strong> do Facebook';
      default:
        return 'Link';
    }
  }

  getFormDescription() {
    switch (this.type) {
      case 'link':
        return {
          name: 'Use o nome do seu produto, serviço ou projeto.',
          placeholder: 'Ex: Meu novo produto',
          url: 'Informe o link completo com https:// ou http://',
          placeholderUrl: 'Ex: https://www.google.com.br',
        };
      case 'store':
        return {
          name: 'Use o nome da sua loja ou coleção.',
          placeholder: 'Ex: Lojinha da Bio',
          url: 'Informe o link completo da loja. Ex: https://www.google.com.br',
          placeholderUrl: 'Ex: https://www.loja.com.br',
        };
      case 'live':
        return {
          name: 'Use o nome da sua live.',
          placeholder: 'Ex: Live de lançamento do meu novo produto',
          url: 'Utilize o link compartilhável da sua live da Live Link!',
          placeholderUrl: 'Ex: https://www.livelink.com.br/streams/a978f8cf...',
          date: `Data e hora da live. Ex: ${Link.getFormattedDate()}`,
        };
      case 'youtube':
        return {
          name: 'Informe um nome pro seu vídeo.',
          placeholder: 'Ex: Vídeo de lançamento do meu novo produto',
          url: 'Informe o link completo de incorporação do vídeo.\nUtilize o "compartilhar > incorporar" e não altere o código gerado.',
        };
      case 'spotify':
        return {
          name: 'Informe o link da sua playlist ou música.',
          placeholder: 'Ex: Músicas para relaxar',
          url: 'Informe o link completo de incorporação do spotify.\nUtilize o "compartilhar > incorporar" e não altere o código gerado.',
        };
      case 'instagram':
        return {
          name: 'Informe o link da sua postagem.',
          placeholder: 'Ex: Postagem de lançamento do meu novo produto',
          url: 'Informe o link completo de incorporação do instagam.\nUtilize o "incorporar" e não altere o código gerado.',
        };
      case 'tiktok':
        return {
          name: 'Informe o link da sua postagem.',
          placeholder: 'Ex: Postagem de lançamento do meu novo produto',
          url: 'Informe o link completo de incorporação do Tiktok.\nUtilize o "compartilhar > integrar" e não altere o código gerado.',
        };
      case 'facebook':
        return {
          name: 'Informe o link da sua postagem.',
          placeholder: 'Ex: Postagem de lançamento do meu novo produto',
          url: 'Informe o link completo de incorporação do Facebook.\nUtilize o incorporar > copiar código do Facebook. Não altere o código gerado.',
        };
      default:
        return 'Link';
    }
  }

  static getIconNameByType(type: string): string[] {
    return new Link({
      type,
    }).getIconName();
  }
}

export default Link;
