import { ref, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import Merchant from '@/models/merchant';
import Pagination from '@/models/pagination';
import eventBus from '@/eventBus/eventBus';

export default defineStore('merchantStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const merchantStore = ref({
    myMerchants: {
      data: [] as Merchant[],
      pagination: {} as Pagination,
    },
    merchants: {
      data: [] as Merchant[],
      pagination: {} as Pagination,
    },
  });

  /*
    Computed
  */
  const getMyMerchant = computed(() => {
    const { myMerchants } = merchantStore.value;
    return (uuid: string): Merchant => {
      const [myMerchant] = myMerchants.data.filter((m) => m.uuid === uuid);
      return myMerchant;
    };
  });

  const getMerchant = ((uuid: string) => {
    const { merchants } = merchantStore.value;
    const merchant = () => merchants.data.filter((m) => m.uuid === uuid);
    return merchant;
  });

  const getSortedMyMerchants = computed(() => {
    const { myMerchants } = merchantStore.value;
    return () => myMerchants.data.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  });

  const getSortedMerchants = computed(() => {
    const { merchants } = merchantStore.value;
    return () => merchants.data.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  });

  const formattedMerchantStatus = computed(() => (status: string) => {
    switch (status) {
      case 'active':
        return 'Ativa';
      case 'inactive':
        return 'Inativa';
      case 'pending':
        return 'Cadastro Incompleto*';
      case 'deleted':
        return 'Deletada';
      default:
        return 'Desconhecido';
    }
  });

  const formattedMerchantStatusDescription = computed(() => (status: string) => {
    switch (status) {
      case 'pending':
        return '** Preencha as informações necessárias para ativar sua loja e poder criar lives para ela';
      default:
        return undefined;
    }
  });

  /*
    Methods
  */
  const updateStoreMyMerchants = (merchants: Merchant[]) => {
    const existingStreamIndex = merchantStore.value.myMerchants.data.findIndex(
      (merchant) => merchant.uuid === merchants[0].uuid,
    );
    if (existingStreamIndex !== -1) { // Stream exists in the list, update its properties
      Object.assign(
        merchantStore.value.myMerchants.data[existingStreamIndex],
        merchants[0],
      );
    } else { // The merchant doesn't exist in the list, add it to the list
      merchantStore.value.myMerchants.data.push(merchants[0]);
    }
  };

  const updateStoreMerchants = (merchants: Merchant[]) => {
    const existingStreamIndex = merchantStore.value.merchants.data.findIndex(
      (merchant) => merchant.uuid === merchants[0].uuid,
    );
    if (existingStreamIndex !== -1) { // Stream exists in the list, update its properties
      Object.assign(
        merchantStore.value.merchants.data[existingStreamIndex],
        merchants[0],
      );
    } else { // The merchant doesn't exist in the list, add it to the list
      merchantStore.value.merchants.data.push(merchants[0]);
    }
  };

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('merchantStore');
    merchantStore.value.myMerchants = {
      data: [] as Merchant[],
      pagination: {} as Pagination,
    };
    merchantStore.value.merchants = {
      data: [] as Merchant[],
      pagination: {} as Pagination,
    };
    // merchantStore.value = JSON.parse(localStorage.getItem('merchantStore') || '{}');
  };

  const merchantDoCreate = async (
    name: string,
    productGateway: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      name,
      product_gateway: productGateway,
    };

    return axios.post('/merchants/add', payload, config)
      .then((response) => response)
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  // Get all merchants logged in user has access to
  const merchantsDoGetMy = async () => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {};

    return axios.post('/merchants/my', payload, config)
      .then((response) => {
        if (response.status === 204) { // No content
          merchantStore.value.myMerchants = {
            data: [] as Merchant[],
            pagination: new Pagination({
              total: 0,
              perPage: 0,
              currentPage: 0,
              lastPage: 0,
            }),
          };
          return merchantStore.value.myMerchants;
        }

        const { columns, rows, pagination } = response.data;
        const merchants = Merchant.fromApiResponse(columns, rows);

        if (merchants.length === 0) {
          return merchantStore.value.myMerchants;
        }

        merchantStore.value.myMerchants.data = merchants;
        merchantStore.value.myMerchants.pagination = pagination;
        return merchantStore.value.myMerchants;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  // Get a single merchant by stream uuid or return undefined
  const merchantsDoSearch = (streamUuid: string): Promise<Merchant> => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      stream_uuid: streamUuid,
    };

    return axios.post('/merchants/search', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          merchantStore.value.merchants.data = [] as Merchant[];
          merchantStore.value.merchants.pagination = {} as Pagination;
          return undefined;
        }

        const { columns, rows, pagination } = response.data;
        const merchants = Merchant.fromApiResponse(columns, rows);

        if (merchants.length === 0) {
          return undefined;
        }

        updateStoreMerchants(merchants);
        merchantStore.value.merchants.pagination = pagination;
        return merchants;
      })
      .catch((error) => error);
  };

  const merchantsDoUpdateMyMerchant = async (
    uuid: string,
    params: Merchant,
    updateImageLogo: boolean,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    let payloadWithImage = {};

    const payload = {
      uuid,
      status: params.status,
      name: params.name,
      short_description: params.shortDescription,
      description: params.description,
      instagram: params.instagram,
      tiktok: params.tiktok,
      youtube: params.youtube,
      facebook: params.facebook,
      linkedin: params.linkedin,
      website: params.website,
      admins_invited: params.adminsInvited,
    };

    if (updateImageLogo) {
      const logoImage = {
        uuid: params.logoImage.uuid,
        cdn_url: params.logoImage.cdnUrl,
        cdn_url_modifiers: params.logoImage.cdnUrlModifiers,
        mime_type: params.logoImage.mimeType,
      };

      payloadWithImage = {
        ...payload,
        logo_image: logoImage,
      };
    }

    return axios.post('/merchants/my/update', updateImageLogo ? payloadWithImage : payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }
        const { message, columns, row } = response.data;
        const merchants = Merchant.fromApiResponse(columns, [row]);

        updateStoreMyMerchants(merchants);
        return {
          message,
          myMerchant: merchants[0],
        };
      })
      .catch((error) => {
        throw error;
      });
  };

  const merchantsDoDelete = async (
    uuid: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      uuid,
      delete: true,
    };

    return axios.post('/merchants/my/update', payload, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }

        if (response.status === 204) { // No content
          return undefined;
        }

        merchantStore.value.myMerchants.data = merchantStore.value.myMerchants.data.filter(
          (merchant) => merchant.uuid !== uuid,
        );

        return true;
      })
      .catch((error) => {
        throw error;
      });
  };

  /*
    Hooks
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('merchantStore')) {
    merchantStore.value = JSON.parse(localStorage.getItem('merchantStore') || '{}');
  }

  watch(merchantStore, (merchantsVal) => {
    localStorage.setItem('merchantStore', JSON.stringify(merchantsVal));
  }, {
    deep: true,
  });

  return {
    // merchantModel: Merchant,
    // resetStore,
    merchantStore,
    getMyMerchant,
    getMerchant,
    getSortedMyMerchants,
    getSortedMerchants,
    formattedMerchantStatus,
    formattedMerchantStatusDescription,
    updateStoreMyMerchants,
    updateStoreMerchants,
    resetStore,
    merchantDoCreate,
    merchantsDoGetMy,
    merchantsDoSearch,
    merchantsDoUpdateMyMerchant,
    merchantsDoDelete,
  };
});
