import ShopifyOauth from '@/models/plugin/shopifyOauth';
import InstagramOauth from '@/models/plugin/instagramOauth';

class Plugin {
  id: string;

  name: string;

  scope: string;

  redirectURI: string;

  type: 'shopify' | 'instagram' | 'woocommerce' | 'magento' | 'bigcommerce' | 'custom';

  version: string;

  token: string;

  ownerUuid: string;

  adminsUuid: string[];

  createdAt: Date;

  updatedAt: Date;

  shopifyOauth: ShopifyOauth | null;

  instagramOauth: InstagramOauth | null;

  isOwner: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    // eslint-disable-next-line no-underscore-dangle
    this.id = data.id || data._id || data.Id;
    this.name = data.name;
    this.scope = data.scope;
    this.type = data.type;
    this.redirectURI = data.redirectURI;
    this.version = data.version;
    this.token = data.token;
    this.ownerUuid = data.ownerUuid;
    this.adminsUuid = data.adminsUuid;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.shopifyOauth = data.shopifyOauth;
    this.instagramOauth = data.instagramOauth;
    this.isOwner = data.isOwner;
  }

  isShopify(): boolean {
    return this.type === 'shopify';
  }

  isInstagram(): boolean {
    return this.type === 'instagram';
  }

  getDescription(): string {
    if (this.isShopify()) {
      return 'Você poderá escolher seus produtos já cadastrados no Shopify para usar na sua lojinha da bio ou em suas Lives.';
    }
    if (this.isInstagram()) {
      return 'Conectar sua conta do Instagram para usar em suas Lives e nos ajudar a verificar sua identidade.';
    }
    return '';
  }

  formattedScopes(): string {
    const scopes = this.scope.split(',');
    return scopes.map((scope) => Plugin.formatScope(scope)).join(', ');
  }

  static formatScope(scope: string): string {
    switch (scope) {
      case 'read_products':
        return 'Ler produtos';
      case 'business_basic': // Instagram
        return 'Acesso básico a informações';
      case 'business_manage_messages': // Instagram
        return 'Gerenciar mensagens';
      case 'business_manage_comments': // Instagram
        return 'Gerenciar comentários';
      case 'business_content_publish': // Instagram
        return 'Publicar conteúdo';
      default:
        return '';
    }
  }

  static newPlugin(params: {
    name: string,
    type: string,
    redirectURI: string,
    shopifyOauth: ShopifyOauth | null,
  }): Plugin {
    return new Plugin({
      id: '',
      name: params.name,
      type: params.type,
      redirectURI: params.redirectURI,
      version: '',
      token: '',
      ownerUuid: '',
      adminsUuid: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      shopifyOauth: params.shopifyOauth ? new ShopifyOauth({
        code: params.shopifyOauth.code,
        hmac: params.shopifyOauth.hmac,
        host: params.shopifyOauth.host,
        shop: params.shopifyOauth.shop,
        state: params.shopifyOauth.state,
        timestamp: params.shopifyOauth.timestamp,
      }) : null,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): Plugin[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (row[index] && (camelCaseKey === 'updatedAt' || camelCaseKey === 'createdAt')) {
          v[camelCaseKey] = new Date(row[index]);
        } else if (row[index] && (camelCaseKey === 'shopifyOauth') && row[index].type === 'shopify') {
          v[camelCaseKey] = new ShopifyOauth(row[index]);
        } else if (row[index] && (camelCaseKey === 'instagramOauth') && row[index].type === 'instagram') {
          v[camelCaseKey] = new InstagramOauth(row[index]);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new Plugin(v);
    });
  }
}

export default Plugin;
