import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useAxios from '@/libs/axios';
import Model from '@/models/contactUs';
import eventBus from '@/eventBus/eventBus';

export default defineStore('contactUsStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const contactUsStore = ref({
    contactUs: {} as Model,
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('contactUsStore');
    // contactUsStore.value = JSON.parse(localStorage.getItem('contactUsStore') || '{}');
  };

  const contactUsDoCreate = async (contactUs: Model) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    return axios.post('/support/contact-us', contactUs, config)
      .then((response) => {
        if (
          response.status !== 200
          && response.status !== 204
        ) {
          return undefined;
        }
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401) {
          throw error;
        } else if (error.message) {
          throw error;
        }
      });
  };

  /*
    Hooks
  */
  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('contactUsStore')) {
    contactUsStore.value = JSON.parse(localStorage.getItem('contactUsStore') || '{}');
  }

  watch(contactUsStore, (contactUsVal) => {
    localStorage.setItem('contactUsStore', JSON.stringify(contactUsVal));
  }, {
    deep: true,
  });

  return {
    resetStore,
    contactUsDoCreate,
  };
});
