import MetadataTypes from '@/models/livereport/metadataTypes';

class Metadata {
  account: string;

  dates: string[];

  types: MetadataTypes[];

  constructor({
    account,
    dates,
    types,
  }: {
    account: string
    dates: string[]
    types: MetadataTypes[]
  }) {
    // eslint-disable-next-line no-underscore-dangle
    this.account = account;
    this.dates = dates;
    this.types = types;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): Metadata[] {
    if (rows.length === 0) {
      return [];
    }
    const customOrder = ['msg', 'img', 'cnt'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        if (camelCaseKey === 'Id') {
          v.account = row[index];
        } else if (camelCaseKey === 'dates') {
          v[camelCaseKey] = row[index];
        } else if (camelCaseKey === 'types') {
          const types = row[index].map((type: MetadataTypes) => new MetadataTypes(type));
          // Sort the types array by custom order if it exists
          if (types && Array.isArray(types)) {
            types.sort((a, b) => customOrder.indexOf(a.eType) - customOrder.indexOf(b.eType));
          }
          v[camelCaseKey] = types;
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new Metadata(v);
    });
  }
}

export default Metadata;
