class Buttons {
  bgHex: string;

  colorHex: string;

  shadowHex: string;

  shadow: string;

  radius: number;

  border: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.bgHex = data.bgHex || data.bg_hex;
    this.colorHex = data.colorHex || data.color_hex;
    this.shadowHex = data.shadowHex || data.shadow_hex;
    this.shadow = data.shadow || data.shadow;
    this.radius = data.radius || data.radius;
    this.border = data.border;
  }

  static newButtons(): Buttons {
    return new Buttons({
      bgHex: '#000000',
      colorHex: '#ffffff',
      shadowHex: '#000000',
      shadow: '0px 0px 0px 0px #000000',
      radius: 0,
      border: '1px solid',
    });
  }
}

export default Buttons;
