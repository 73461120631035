import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';
import useAxios from '@/libs/axios';
import eventBus from '@/eventBus/eventBus';
import mixpanelStore from '@/store/mixpanel/mixpanel';

export default defineStore('authStore', () => {
  const axios = useAxios();

  /*
    State
  */
  const session = ref({
    hasAccessToken: false,
    language: '',
    name: '',
    status: '',
    timezone: '',
    ipAddress: '',
    storeMode: 'customer', // customer | merchant
  });

  /*
    Getters
  */
  const getToken = (): string => {
    const { cookies } = useCookies();
    return cookies.get('access_token');
  };
  const isAuthenticated = (): boolean => (
    !!session.value?.hasAccessToken
    && !!getToken()
  );
  const isStoreModeCustomer = (): boolean => (
    !!session.value?.hasAccessToken
    && !!getToken() && session.value?.storeMode === 'customer'
  );
  const isStoreModeMerchant = (): boolean => (
    !!session.value?.hasAccessToken
    && !!getToken() && session.value?.storeMode === 'merchant'
  );
  const mustChangePassword = (): boolean => session.value?.status === 'REQUEST_PASSWORD_UPDATE_EMAIL';
  // const getUserFirstName = (): string => session.value?.name.split(' ')[0];

  /*
    Actions
  */

  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('authStore');
    // session.value = JSON.parse(localStorage.getItem('authStore') || '{}');
  };

  // This event is used to force logout in all tabs
  // when user logout in one tab
  // This is necessary because localStorage is shared
  // between tabs
  const emitLogout = async () => {
    eventBus.emit('event:logout');
  };

  const toggleStoreMode = async () => {
    if (!session.value.storeMode || session.value.storeMode === 'customer') {
      session.value.storeMode = 'merchant';
    } else {
      session.value.storeMode = 'customer';
    }
  };

  const getStoreMode = async () => session.value.storeMode;

  // This method is used to force logout in all tabs
  const authDoForceLogout = async () => {
    emitLogout();
  };

  const authDoLogin = async (
    document: string,
    documentType: string,
    password: string,
  ) => {
    const { cookies } = useCookies();

    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      document,
      documentType,
      password,
    };

    return axios.post(
      '/session/login',
      payload,
      config,
    ).then((response) => {
      mixpanelStore.userIdentify(response.data?.uuid);

      cookies.set('access_token', response.data?.token, '1d');
      session.value.hasAccessToken = true;
      session.value.language = response.data?.language;
      session.value.name = response.data?.name;
      session.value.status = response.data?.status;
      session.value.timezone = response.data?.timezone;
      session.value.ipAddress = response.data?.ip_address;
      session.value.storeMode = 'customer';
      return response;
    }).catch((error) => {
      if (error.response?.data?.error !== undefined) {
        throw new Error(error.response.data.error);
      } else if (error.response?.data?.errors !== undefined) {
        throw new Error(error.response.data.errors);
      } else {
        throw error;
      }
    });
  };

  // This method is used to refresh the access token
  // when it expires
  const authDoLoginRefresh = async () => {
    const { cookies } = useCookies();

    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {};

    return axios.post(
      '/session/login/refresh',
      payload,
      config,
    ).then((response) => {
      cookies.set('access_token', response.data?.token, '1d');
      session.value.hasAccessToken = true;
      session.value.language = response.data?.language;
      session.value.name = response.data?.name;
      session.value.status = response.data?.status;
      session.value.timezone = response.data?.timezone;
      session.value.ipAddress = response.data?.ip_address;
      return response;
    }).catch((error) => {
      session.value.hasAccessToken = false;
      session.value.language = 'pt-BR';
      session.value.name = '';
      session.value.status = '';
      session.value.timezone = '';
      cookies.remove('access_token');

      if (error.response?.data?.error !== undefined) {
        throw new Error(error.response.data.error);
      } else if (error.response?.data?.errors !== undefined) {
        throw new Error(error.response.data.errors);
      } else {
        throw error;
      }
    });
  };

  // This method is used to logout user
  // It will remove the access token from cookies
  // and reset the store
  const authDoLogout = async () => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {};

    return axios.post(
      '/session/logout',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch(
      (error) => error,
    ).finally(() => {
      emitLogout();
    });
  };

  // This method is used to create a new user
  // It will send an email to the user with
  // a first access password
  const authDoSignup = async (
    language: string,
    timezone: string,
    fullName: string,
    document: string,
    documentType: string,
    email: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      language,
      timezone,
      full_name: fullName,
      document,
      document_type: documentType,
      email,
    };

    return axios.post(
      '/session/signup',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch((error) => {
      if (error.response?.data?.error !== undefined) {
        throw new Error(error.response.data.error);
      } else if (error.response?.data?.errors !== undefined) {
        throw new Error(error.response.data.errors);
      } else {
        throw error;
      }
    });
  };

  // This method is used to recover user password
  // It will send an email to the user with
  // a temporary password
  const authDoPasswordRecover = async (
    recoverMethod: string,
    documentType: string,
    document: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      recover_method: recoverMethod,
      document_type: documentType,
      document,
    };

    return axios.post(
      '/session/password/recover',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch((error) => {
      if (error.response?.data?.error !== undefined) {
        throw new Error(error.response.data.error);
      } else if (error.response?.data?.errors !== undefined) {
        throw new Error(error.response.data.errors);
      } else {
        throw error;
      }
    });
  };

  // This method is used to update user password
  // It will update the user password as long as
  // the user is in a mustChangePassword status
  const authDoPasswordUpdate = async (
    password: string,
    passwordConfirmation: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      password,
      password_confirmation: passwordConfirmation,
      validate_current_password: false,
    };

    return axios.post(
      '/session/password/update',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch((error) => {
      if (error.response?.data?.error !== undefined) {
        throw new Error(error.response.data.error);
      } else if (error.response?.data?.errors !== undefined) {
        throw new Error(error.response.data.errors);
      } else {
        throw error;
      }
    });
  };

  const authDoCurrentPasswordUpdate = async (
    password: string,
    passwordConfirmation: string,
    currentPassword: string,
  ) => {
    const config = {
      headers: {
        'Accept-Language': 'pt-BR',
      },
    };

    const payload = {
      password,
      password_confirmation: passwordConfirmation,
      current_password: currentPassword,
      validate_current_password: true,
    };

    return axios.post(
      '/session/password/update',
      payload,
      config,
    ).then(
      (response) => response,
    ).catch((error) => {
      if (error.response?.data?.error !== undefined) {
        throw new Error(error.response.data.error);
      } else if (error.response?.data?.errors !== undefined) {
        throw new Error(error.response.data.errors);
      } else {
        throw error;
      }
    });
  };

  /*
    Config
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('authStore')) {
    session.value = JSON.parse(localStorage.getItem('authStore') || '{}');
  }

  watch(session, (sessionVal) => {
    localStorage.setItem('authStore', JSON.stringify(sessionVal));
  }, {
    deep: true,
  });

  return {
    session,
    isAuthenticated,
    isStoreModeCustomer,
    isStoreModeMerchant,
    toggleStoreMode,
    getStoreMode,
    mustChangePassword,
    authDoForceLogout,
    authDoLogin,
    authDoLoginRefresh,
    authDoLogout,
    authDoSignup,
    authDoPasswordRecover,
    authDoPasswordUpdate,
    authDoCurrentPasswordUpdate,
  };
});
