import Product from '@/models/yampi/product/product';
import Sku from '@/models/yampi/product/sku';

class ProductQuantity {
  quantity: number;

  totalPrice: number;

  productSku: Sku | null;

  product: Product | null;

  constructor({
    quantity,
    productSku,
    product,
  }: {
      quantity: number;
      productSku: Sku;
      product: Product;
    }) {
    this.quantity = quantity;
    this.quantity = quantity;
    this.productSku = new Sku(productSku);
    this.totalPrice = (this.productSku.getPrice() || 0) * quantity;
    this.product = new Product(product);
  }

  getCheckoutUrlParams(): string {
    const { productSku } = this;
    if (productSku === null) {
      throw new Error('productSku is null');
    }
    const { productId } = productSku;
    if (productId === null) {
      throw new Error('product is null');
    }
    const token = this.productSku?.token;
    if (token === null) {
      throw new Error('token is null');
    }
    return `${token}:${this.quantity}`;
  }

  // TODO add validations for quantity
  increaseQuantity() {
    this.quantity += 1;
  }

  decreaseQuantity() {
    if (this.quantity === 0) {
      return;
    }
    this.quantity -= 1;
  }

  deleteQuantity() {
    this.quantity = 0;
  }

  getTotalPrice() {
    const v = this.productSku?.getPrice();
    if (v !== null) {
      return this.quantity * (v || 0);
    }
    return 0;
  }
}

export default ProductQuantity;
