import Image from '@/models/image';
import RecordingData from '@/models/recordingData';

class Stream {
  uuid: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  createdByUuid: string;

  merchantUuid: string;

  status: string;

  name: string;

  adminsFull: Array<string>;

  adminsRead: Array<string>;

  adminsInvited: Array<string>;

  facebook: string;

  linkedin: string;

  instagram: string;

  tiktok: string;

  youtube: string;

  website: string;

  coverImage: Image;

  description: string;

  uri: string;

  scheduledDate: Date;

  landingPageActive: boolean;

  yampiGroupId: number;

  yampiBrandId: number;

  productGateway: number;

  productsUuid: string;

  activeProductsId: string[];

  featuredProductId: string;

  showProductsOnLanding: boolean;

  recordingStatus: string;

  recordingId: number;

  autoStartRecording: boolean;

  allowChat: boolean;

  recordingData: RecordingData;

  rtmpUrl: string;

  bgImageUrl: string;

  streamMode: string;

  logoHtml: string;

  constructor({
    uuid,
    createdAt,
    updatedAt,
    deletedAt,
    createdByUuid,
    merchantUuid,
    status,
    name,
    adminsFull,
    adminsRead,
    adminsInvited,
    facebook,
    linkedin,
    instagram,
    tiktok,
    youtube,
    website,
    coverImage,
    description,
    uri,
    scheduledDate,
    landingPageActive,
    yampiGroupId,
    yampiBrandId,
    productGateway,
    productsUuid,
    activeProductsId,
    featuredProductId,
    showProductsOnLanding,
    recordingStatus,
    recordingId,
    autoStartRecording,
    allowChat,
    recordingData,
    rtmpUrl,
    bgImageUrl,
    streamMode,
    logoHtml,
  }: {
    uuid: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    createdByUuid: string;
    merchantUuid: string;
    status: string;
    name: string;
    adminsFull: Array<string>;
    adminsRead: Array<string>;
    adminsInvited: Array<string>;
    facebook: string;
    linkedin: string;
    instagram: string;
    tiktok: string;
    youtube: string;
    website: string;
    coverImage: Image;
    description: string;
    uri: string;
    scheduledDate: Date;
    landingPageActive: boolean;
    yampiGroupId: number;
    yampiBrandId: number;
    productGateway: number;
    productsUuid: string;
    activeProductsId: string[];
    featuredProductId: string;
    showProductsOnLanding: boolean;
    recordingStatus: string;
    recordingId: number;
    autoStartRecording: boolean;
    allowChat: boolean;
    recordingData: RecordingData;
    rtmpUrl: string;
    bgImageUrl: string;
    streamMode: string;
    logoHtml: string;
  }) {
    this.uuid = uuid;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
    this.createdByUuid = createdByUuid;
    this.merchantUuid = merchantUuid;
    this.status = status;
    this.name = name;
    this.adminsFull = adminsFull;
    this.adminsRead = adminsRead;
    this.adminsInvited = adminsInvited;
    this.facebook = facebook;
    this.linkedin = linkedin;
    this.instagram = instagram;
    this.tiktok = tiktok;
    this.youtube = youtube;
    this.website = website;
    this.coverImage = coverImage;
    this.description = description;
    this.uri = uri;
    this.scheduledDate = scheduledDate;
    this.landingPageActive = landingPageActive;
    this.landingPageActive = landingPageActive;
    this.yampiBrandId = yampiBrandId;
    this.yampiGroupId = yampiGroupId;
    this.productGateway = productGateway;
    this.productsUuid = productsUuid;
    this.activeProductsId = activeProductsId;
    this.featuredProductId = featuredProductId;
    this.showProductsOnLanding = showProductsOnLanding;
    this.recordingStatus = recordingStatus;
    this.recordingId = recordingId;
    this.autoStartRecording = autoStartRecording;
    this.allowChat = allowChat;
    this.recordingData = recordingData;
    this.rtmpUrl = rtmpUrl;
    this.bgImageUrl = bgImageUrl;
    this.streamMode = streamMode;
    this.logoHtml = logoHtml;
  }

  isRecording(): boolean {
    return this.recordingStatus === 'recording';
  }

  getName(): string {
    return this.name;
  }

  getCoverImage(modifiers: string): string | null {
    let mod = modifiers;
    if (modifiers == null) {
      mod = '';
    }
    if (this.coverImage && this.coverImage.cdnUrl) {
      return `${this.coverImage.cdnUrl}${mod}`;
    }
    return null;
  }

  formattedDate(
    minutesToAdd: 0,
    locale: 'en-US',
    isoFormat: false,
  ): string | null {
    const date = new Date(this.scheduledDate);
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    // Create the date object directly with the string representation
    if (Number.isNaN(date.getTime())) {
      return null;
    }

    if (minutesToAdd !== 0 && !Number.isNaN(minutesToAdd)) {
      date.setMinutes(date.getMinutes() + minutesToAdd);
    }
    if (isoFormat) {
      return date.toISOString();
    }
    return date.toLocaleDateString(locale, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): Stream[] {
    if (rows.length === 0) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        // if (camelCaseKey === 'scheduledDate') {
        // v[camelCaseKey] = convertToDateWithoutHour(row[index]);
        // } else
        if (camelCaseKey === 'coverImage') {
          if (row[index] == null || row[index].uuid === '00000000-0000-0000-0000-000000000000') {
            v[camelCaseKey] = undefined;
          } else {
            v[camelCaseKey] = new Image({
              uuid: row[index].uuid,
              cdnUrl: row[index].cdn_url,
              cdnUrlModifiers: row[index].cdn_url_modifiers,
              mimeType: row[index].mime_type,
            });
          }
        } else if (
          row[index]
          && (
            camelCaseKey === 'updatedAt'
            || camelCaseKey === 'createdAt'
            || camelCaseKey === 'deletedAt'
            || camelCaseKey === 'scheduledDate'
          )
        ) {
          v[camelCaseKey] = new Date(row[index].Time);
        } else {
          v[camelCaseKey] = row[index];
        }
      });
      return new Stream(v);
    });
  }
}

export default Stream;
