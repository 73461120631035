class MetadataTypes {
  eType: 'cnt' | 'msg' | 'img';

  count: number;

  constructor({ eType, count }: { eType: 'cnt' | 'msg' | 'img'; count: number }) {
    this.eType = eType;
    this.count = count;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiResponse(columns: string[], rows: any[][]): MetadataTypes[] {
    if (rows.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rows.map((row: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = {};
      columns.forEach((column, index) => {
        const camelCaseKey = column.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
        v[camelCaseKey] = row[index];
      });
      return new MetadataTypes(v);
    });
  }
}

export default MetadataTypes;
