import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import eventBus from '@/eventBus/eventBus';
import UtmParams from '@/models/utmParams';

export default defineStore('utmStore', () => {
  /*
    State
  */
  const utmStore = ref({
    utmParams: {
      data: new UtmParams({
        utmSource: null,
        utmMedium: null,
        utmCampaign: null,
        utmTerm: null,
        utmContent: null,
      }),
    },
  });

  /*
    Actions
  */
  // Reset the whole store and remove it from localStorage
  // after user logout
  const resetStore = () => {
    localStorage.removeItem('utmStore');
    utmStore.value.utmParams.data = new UtmParams({
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmTerm: null,
      utmContent: null,
    });
  };

  const setUTMParameters = () => {
    const query = new URLSearchParams(window.location.search);
    const newParams = {
      utmSource: query.get('utm_source') || null,
      utmMedium: query.get('utm_medium') || null,
      utmCampaign: query.get('utm_campaign') || null,
      utmTerm: query.get('utm_term') || null,
      utmContent: query.get('utm_content') || null,
    };

    // Only update if there are new UTMs or expired UTMs
    if (utmStore.value.utmParams.data.expiresAt < new Date()
      || Object.values(newParams).some((v) => v !== null)) {
      utmStore.value.utmParams.data = new UtmParams(newParams);
    }
  };

  const getUTMParameters = (): UtmParams | null => {
    const params = utmStore.value.utmParams.data;
    if (params.expiresAt && params.expiresAt < new Date()) {
      resetStore();
      return null;
    }
    return new UtmParams(params);
  };

  /*
    Config
  */

  // Listen to logout event
  // This event is used to clear the store
  eventBus.on('event:logout', resetStore);

  // Load store from localStorage
  // This is necessary to keep the store
  // when user refresh the page
  if (localStorage.getItem('utmStore')) {
    try {
      const v = JSON.parse(localStorage.getItem('utmStore') || '{}');
      if (v.utmParams.data.expiresAt) {
        v.utmParams.data.expiresAt = new Date(v.utmParams.data.expiresAt);
      }
      utmStore.value.utmParams.data = new UtmParams(v.utmParams.data);
    } catch (error) {
      console.error('Error parsing UTM data from localStorage', error);
      resetStore();
    }
  }

  watch(utmStore, (utmParamsVal) => {
    localStorage.setItem('utmStore', JSON.stringify(utmParamsVal));
  }, {
    deep: true,
  });

  return {
    utmParamModel: UtmParams,
    resetStore,
    setUTMParameters,
    getUTMParameters,
  };
});
