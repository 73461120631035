<template>
  <figure class="is-flex is-justify-content-center is-align-items-center">
    <img
      src="@/assets/logo2.png"
      alt="logo"
      style="
      width: 130px; max-width: 130px; height: 28px; min-height: 28px;"
    >
  </figure>
</template>
