/* eslint-disable max-len */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import { useStore } from 'vuex';
import MixpanelPageViewStore from '@/store/mixpanel/pageView';
import UtmParamsStore from '@/store/utm/utm';

import GuestLayout from '@/components/layout/GuestLayout.vue';
import NoHeaderLayout from '@/components/layout/NoHeaderLayout.vue';
import HomeView from '@/views/HomeView.vue';
import AuthStore from '@/store/auth';
import sessionRoutes from '@/router/session';
import userRoutes from '@/router/user';
import merchantRoutes from '@/router/merchant';
import dashboardRoutes from '@/router/dashboard';
import liveRoutes from '@/router/stream';
import pluginsRoutes from '@/router/plugins';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomeView,
    meta: {
      layout: GuestLayout,
      accessWithSession: undefined,
    },
  },
  {
    path: '/@:username',
    name: 'BioPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "bio" */ '@/views/user/bio/MainView.vue'),
  },
  {
    path: '/about',
    name: 'AboutPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ '@/views/AboutView.vue'),
    meta: {
      layout: GuestLayout,
      accessWithSession: undefined,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditionsPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "main" */ '@/views/TermsAndConditionsView.vue'),
  },
  {
    path: '/terms-and-conditions/shoppers',
    name: 'TermsAndConditionsPageShoppers',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "main" */ '@/views/TermsAndConditionsShoppersView.vue'),
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "main" */ '@/views/PrivacyView.vue'),
  },
  // merge routes
  ...sessionRoutes,
  ...dashboardRoutes,
  ...userRoutes,
  ...merchantRoutes,
  ...liveRoutes,
  ...pluginsRoutes,
  {
    path: '/oops-404', // matches any path
    name: 'NotFoundPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "error" */ '@/views/errors/404View.vue'),
  },
  {
    path: '/error', // matches any path
    name: 'ErrorPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "error" */ '@/views/errors/ErrorView.vue'),
  },
  {
    path: '/oops-500', // matches any path
    name: 'InternalServerErrorPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "error" */ '@/views/errors/ErrorView.vue'),
  },
  {
    path: '/:catchAll(.*)', // matches any path
    // name: 'NotFoundPage',
    meta: {
      layout: NoHeaderLayout,
      accessWithSession: undefined,
    },
    component: () => import(/* webpackChunkName: "error" */ '@/views/errors/404View.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from, savedPosition) {
    // If the route has a hash (#), scroll to the element with that ID
    if (to.hash) {
      return { el: to.hash };
    }
    // If the route has a saved scroll position, use it
    if (savedPosition) {
      return savedPosition;
    }
    // Scroll to the top of the page
    return { top: 0, left: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Setting UTM parameters on storage
  const { setUTMParameters } = UtmParamsStore();

  // Always set UTM parameters if present in the URL
  if (to.query.utm_source || to.query.utm_medium || to.query.utm_campaign || to.query.utm_term || to.query.utm_content) {
    setUTMParameters();
  }

  const { isAuthenticated, mustChangePassword } = AuthStore();

  // User is trying to access a page that does not require auth
  if (to.meta.accessWithSession === undefined) {
    // alert('User is trying to access a page that does not care about auth');
    next();
    return;
  }

  // User is unauthenticated and trying to access a page that requires authentication
  if (to.meta.accessWithSession === true && !isAuthenticated()) {
    // alert('User is unauthenticated and trying to access a page that requires authentication');
    next({
      name: 'SessionLoginPage',
      query: {
        redirect: to.fullPath,
      },
    });
    return;
  }

  // User is authenticated and trying to access a page that does not accept authenticated users
  if (to.meta.accessWithSession === false && isAuthenticated()) {
    // alert('User is authenticated and trying to access a page that does not accept authenticated users');
    const previousUrl = from.fullPath || '/';
    next(previousUrl);
    return;
  }

  // User is logging in and must change password
  if (
    to.name !== 'SessionPasswordResetPage'
    && to.name !== 'SessionLoginRefreshPage'
    && mustChangePassword()
  ) {
    next({
      name: 'SessionPasswordResetPage',
      query: {
        redirect: to.fullPath,
      },
    });
    return;
  }

  // Default case
  next();
});

router.afterEach((to, from) => {
  new MixpanelPageViewStore().trackPageView(
    from.name,
    to.name,
    to.path,
  );
});

export default router;
