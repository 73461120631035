import 'bulma/css/bulma.min.css';
import money from 'v-money3';
import * as Sentry from '@sentry/vue';

import { QuillEditor } from '@vueup/vue-quill';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faEye,
  faFileVideo,
  faExclamationTriangle,
  faEyeSlash,
  faUserSecret,
  faLink,
  faSearchPlus,
  faPaintBrush,
  faQuestion,
  faQuestionCircle,
  faAlignLeft,
  faExternalLink,
  faToggleOn,
  faToggleOff,
  faExchange,
  faExclamationCircle,
  faFilm,
  faCalendar,
  faChevronDown,
  faCloudDownload,
  faChevronRight,
  faChevronUp,
  faGlobe,
  faClose,
  faPowerOff,
  faCreditCard,
  faHeart,
  faShoppingCart,
  faUser,
  faVideoCamera,
  faVideoSlash,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faCogs,
  faRefresh,
  faRocket,
  faComments,
  faTags,
  faInfoCircle,
  faLineChart,
  faUpRightFromSquare,
  faHome,
  faBriefcase,
  faPencil,
  faTrash,
  faBuilding,
  faImage,
  faCalendarPlus,
  faShareAlt,
  faShareAltSquare,
  faClock,
  faClipboard,
  faClipboardList,
  faCheckCircle,
  faTimesCircle,
  faCheck,
  faVolumeUp,
  faGear,
  faMicrophone,
  faMicrophoneSlash,
  faCircle,
  faShoppingBag,
  faImagePortrait,
  faPaperPlane,
  faChartLine,
  faList,
  faUsers,
  faUserCircle,
  faSignOut,
  faEnvelope,
  faMapMarkerAlt,
  faBook,
  faBell,
  faFileInvoiceDollar,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faWhatsapp,
  faTwitter,
  faInstagram,
  faYoutube,
  faSpotify,
  faLinkedin,
  faTiktok,
  faTelegram,
  faBehance,
  faTwitch,
} from '@fortawesome/free-brands-svg-icons';
import Notifications from '@kyvg/vue3-notification';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

library.add(
  faEye,
  faFileVideo,
  faExclamationTriangle,
  faEyeSlash,
  faUserSecret,
  faLink,
  faSearchPlus,
  faPaintBrush,
  faQuestion,
  faQuestionCircle,
  faAlignLeft,
  faExternalLink,
  faToggleOn,
  faToggleOff,
  faExchange,
  faExclamationCircle,
  faFilm,
  faCalendar,
  faChevronDown,
  faCloudDownload,
  faChevronRight,
  faChevronUp,
  faGlobe,
  faClose,
  faPowerOff,
  faCreditCard,
  faHeart,
  faShoppingCart,
  faUser,
  faVideoCamera,
  faVideoSlash,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faCogs,
  faRefresh,
  faRocket,
  faComments,
  faTags,
  faInfoCircle,
  faLineChart,
  faUpRightFromSquare,
  faHome,
  faBriefcase,
  faPencil,
  faTrash,
  faBuilding,
  faImage,
  faCalendarPlus,
  faShareAlt,
  faShareAltSquare,
  faClock,
  faClipboard,
  faClipboardList,
  faCheckCircle,
  faTimesCircle,
  faCheck,
  faVolumeUp,
  faGear,
  faMicrophone,
  faMicrophoneSlash,
  faCircle,
  faShoppingBag,
  faImagePortrait,
  faPaperPlane,
  faChartLine,
  faList,
  faUsers,
  faUserCircle,
  faSignOut,
  faEnvelope,
  faMapMarkerAlt,
  faBook,
  faBell,
  faFileInvoiceDollar,
  faAngleLeft,
  faAngleRight,

  faFacebook,
  faWhatsapp,
  faTwitter,
  faInstagram,
  faYoutube,
  faSpotify,
  faLinkedin,
  faTiktok,
  faTelegram,
  faBehance,
  faTwitch,
);

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control
      // for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/livelink\.com.br/],
      // tracePropagationTargets: ['localhost', /^https:\/\/livelink\.com.br\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 20% of transactions for production
  // Session Replay
  // This sets the sample rate at 10%. You may want to change
  // it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.05, // Reduce if you're hitting limits
  // If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 0.25, // Record fewer error sessions
});

app
  .component('QuillEditor', QuillEditor)
  .component('FontAwesomeIcon', FontAwesomeIcon);

const pinia = createPinia(); // Create the Pinia instance
app
  .use(pinia)
  .use(router)
  .use(money)
  .use(Notifications)
  .mount('#app');
