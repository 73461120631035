class Social {
  instagram: string;

  tiktok: string;

  youtube: string;

  facebook: string;

  linkedin: string;

  website: string;

  twitter: string;

  whatsapp: string;

  twitch: string;

  telegram: string;

  behance: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.instagram = data.instagram;
    this.tiktok = data.tiktok;
    this.youtube = data.youtube;
    this.facebook = data.facebook;
    this.linkedin = data.linkedin;
    this.website = data.website;
    this.twitter = data.twitter;
    this.whatsapp = data.whatsapp;
    this.twitch = data.twitch;
    this.telegram = data.telegram;
    this.behance = data.behance;
  }

  static newSocial(): Social {
    return new Social({
      instagram: '',
      tiktok: '',
      youtube: '',
      facebook: '',
      linkedin: '',
      website: '',
      twitter: '',
      whatsapp: '',
      twitch: '',
      telegram: '',
      behance: '',
    });
  }

  static getPrefix(network: string): string {
    switch (network) {
      case 'instagram':
        return 'https://instagram.com/';
      case 'tiktok':
        return 'https://tiktok.com/';
      case 'youtube':
        return 'https://youtube.com/';
      case 'facebook':
        return 'https://facebook.com/';
      case 'linkedin':
        return 'https://linkedin.com/in/';
      case 'website':
        return 'https://';
      case 'twitter':
        return 'https://twitter.com/';
      case 'whatsapp':
        return 'https://wa.me/+55';
      case 'twitch':
        return 'https://twitch.tv/';
      case 'telegram':
        return 'https://t.me/+55';
      case 'behance':
        return 'https://behance.net/';
      default:
        return '';
    }
  }
}

export default Social;
